define('app-ember/components/freestyle/bootstrap/radio-group', ['exports', 'ember-bootstrap-controls/components/freestyle/bootstrap/radio-group'], function (exports, _radioGroup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _radioGroup.default;
    }
  });
});