define("app-ember/controllers/story-creation/sample-story", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    currentExampleIndex: null,

    actions: {
      next: function next() {
        this.transitionToRoute("story-creation");
      }
    }
  });
});