define('app-ember/routes/base-authenticated', ['exports', 'ember-authenticate-me/routes/authenticated', 'app-ember/utils/generate-uuid'], function (exports, _authenticated, _generateUuid) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticated.default.extend({
    session: Ember.inject.service('session'),

    beforeModel: function beforeModel() {
      var _this = this;

      return this._super.apply(this, arguments).then(function () {

        if (window.localStorage.getItem('eam-token') === null) {
          window.localStorage.setItem('eam-token', (0, _generateUuid.default)());
        }

        var session = _this.get('session');
        return Ember.RSVP.resolve(session).then(function (resolvedSession) {
          return resolvedSession.get('currentUser');
        });
      });
    }
  });
});