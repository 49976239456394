define('app-ember/routes/invite/create-organization', ['exports', 'app-ember/routes/base-authenticated'], function (exports, _baseAuthenticated) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseAuthenticated.default.extend({
    session: Ember.inject.service('session'),

    model: function model() {
      var _this = this;

      return this.get('session.content.currentUser.profileable').then(function (currentUserProfile) {
        return _this.get('store').createRecord('organization', {
          primaryContactProfile: currentUserProfile,
          status: 'active'
        });
      }).catch(function (e) {
        throw e;
      });
    }
  });
});