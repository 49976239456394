define('app-ember/helpers/ordinal-for', ['exports', 'ember-ordinal-indicators/helpers/ordinal-for'], function (exports, _ordinalFor) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _ordinalFor.default;
    }
  });
  Object.defineProperty(exports, 'ordinalFor', {
    enumerable: true,
    get: function () {
      return _ordinalFor.ordinalFor;
    }
  });
});