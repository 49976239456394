define('app-ember/initializers/sentry', ['exports', 'app-ember/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;

  /* global Raven */

  function initialize() {
    // Sentry Reporting Urls (Stored on Heroku as env variables)
    // staging - https://4cc52ca354aa4e0d9adefb9ad30d59b3@sentry.io/282261
    // production - https://fa7dd070a2c24bb785633f4856590b32@sentry.io/282262
    // development - https://8dd6c2985af64cfaa54e1e096ae3f803@sentry.io/282259
    if (_environment.default.APP.SENTRY.CONFIG_URL) {
      Raven.config(_environment.default.APP.SENTRY.CONFIG_URL).addPlugin(Raven.Plugins.Ember).install();
    }
  }

  exports.default = {
    name: 'sentry',
    initialize: initialize
  };
});