define('app-ember/components/ws-footer', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    tagName: '',

    currentUser: Ember.computed.alias('session.content.currentUser')
  });
});