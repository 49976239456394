define('app-ember/utils/s3-uploader', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.S3Uploader.extend({
    uuid: null,
    headers: null,

    toNamespacedParam: function toNamespacedParam(name) {
      if (this.paramNamespace) {
        return this.paramNamespace + '[' + name + ']';
      }

      return name;
    },

    didSign: function didSign(response) {
      this.trigger('didSign', response);
    },


    ajax: function ajax(url, params, method, filename) {
      var self = this;
      var settings = {
        url: url,
        type: method || 'POST',
        contentType: false,
        headers: { 'response-content-disposition': 'attachment; filename=' + filename },
        processData: false,
        xhr: function xhr() {
          var xhr = Ember.$.ajaxSettings.xhr();
          xhr.upload.onprogress = function (e) {
            self.didProgress(e);
          };
          return xhr;
        },
        data: params
      };

      return this._ajax(settings);
    },

    sign: function sign(file, data) {
      data = data || {};
      data.name = file.name;
      data.type = file.type;
      data.size = file.size;
      data.s3key = this.get('uuid');

      var settings = {
        url: Ember.get(this, 'url'),
        headers: Ember.get(this, 'headers'),
        type: 'GET',
        contentType: 'json',
        data: data
      };

      return this._ajax(settings);
    },


    setupFormData: function setupFormData(file, extra, fileName) {
      var formData = new FormData();

      for (var prop in extra) {
        if (extra.hasOwnProperty(prop)) {
          formData.append(this.toNamespacedParam(prop), extra[prop]);
        }
      }

      formData.append(this.toNamespacedParam(this.paramName), file, fileName);
      return formData;
    },

    upload: function upload(file, data) {
      var _this = this;

      Ember.set(this, 'isUploading', true);

      return this.sign(file, data).then(function (json) {
        _this.didSign(json);
        var url = null;
        if (json.region) {
          url = '//s3-' + json.region + '.amazonaws.com/' + json.bucket;
          delete json.region;
        } else {
          url = '//' + json.bucket + '.s3.amazonaws.com';
        }

        var formData = _this.setupFormData(file, json, file.name);

        return _this.ajax(url, formData, null, file.name);
      }).then(function (respData) {
        //this.didUpload(respData);
        return respData;
      });
    }
  });
});