define('app-ember/helpers/concat-class-names', ['exports', 'ember-bootstrap-controls/helpers/concat-class-names'], function (exports, _concatClassNames) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _concatClassNames.default;
    }
  });
  Object.defineProperty(exports, 'concatClassNames', {
    enumerable: true,
    get: function () {
      return _concatClassNames.concatClassNames;
    }
  });
});