define('app-ember/components/bootstrap/buttons/reset-button', ['exports', 'ember-bootstrap-controls/components/bootstrap/buttons/reset-button'], function (exports, _resetButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _resetButton.default;
    }
  });
});