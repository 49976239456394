define('app-ember/models/invitation', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    requestor: _emberData.default.belongsTo('token-authenticate-me/user', { async: true }),
    role: _emberData.default.belongsTo('role', { async: true }),
    organization: _emberData.default.belongsTo('organization', { async: true }),
    acceptor: _emberData.default.belongsTo('token-authenticate-me/user', { async: true }),

    jobSuggestions: _emberData.default.hasMany('job-suggestion', { async: true }),

    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    username: _emberData.default.attr('string'),
    uuid: _emberData.default.attr('string'),
    organizationTitle: _emberData.default.attr('string'),
    requestorName: _emberData.default.attr('string'),
    zipCode: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    profileType: _emberData.default.attr('string'),

    reinvite: _emberData.default.attr('boolean'),

    expiresAt: _emberData.default.attr('date'),
    sentAt: _emberData.default.attr('date'),

    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),

    /* not persisted in the local store, only sent to server */
    password: null,
    passwordConfirmation: null,
    selectedJobsCache: [],

    fullName: Ember.computed('firstName', 'lastName', function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    }),

    expired: Ember.computed('expiresAt', function () {
      return (0, _moment.default)().isAfter(this.get('expiresAt'));
    }),

    alreadyAccepted: Ember.computed('acceptor', function () {
      return this.get('acceptor.id');
    })

  });
});