define('app-ember/components/ws-card/flippy', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['row'],
    frontSide: true,

    actions: {
      toggleFlip: function toggleFlip() {
        this.toggleProperty('frontSide');
      }
    }
  });
});