define('app-ember/routes/admin/invitations', ['exports', 'app-ember/routes/admin', 'ember-bootstrap-controls/mixins/routes/pagination'], function (exports, _admin, _pagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _admin.default.extend(_pagination.default, {
    session: Ember.inject.service('session'),
    invitationsEntity: Ember.inject.service('model-entities/invitations'),

    model: function model(params) {
      return this.get('invitationsEntity').fetchInvitations({
        page: this.paginationParams(params),
        filters: {
          requestor: this.get('session.content.currentUser.id')
        },
        sort: {
          criteria: 'created_at',
          reverse: true
        }
      });
    },


    afterModel: function afterModel(model) {
      this._super.apply(this, arguments); // Call the afterModel from the mixin
    },

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
    }
  });
});