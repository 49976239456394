define('app-ember/models/story-v1', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    profileMember: _emberData.default.belongsTo('profile-member', { async: true }),
    topic: _emberData.default.belongsTo('topic', { async: true }),
    surveyQuestionUserAnswer: _emberData.default.belongsTo('survey-question-user-answer', { async: true, inverse: 'story' }),

    storyAttributes: _emberData.default.hasMany('story-attribute', { async: true }),

    description: _emberData.default.attr('string'),
    favorite: _emberData.default.attr('boolean'),
    published: _emberData.default.attr('boolean', {
      defaultValue: function defaultValue() {
        return true;
      }
    }),

    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),

    pdfText: _emberData.default.attr('string')
  });
});