define('app-ember/services/model-entities/work-skills', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service('store'),

    fetchWorkSkill: function fetchWorkSkill(id) {
      if (id) {
        return this.get('store').find('work-skill', id);
      }
    },
    fetchSingleWorkSkill: function fetchSingleWorkSkill() {
      var queryParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var defaultQueryParams = {};

      return this.get('store').queryRecord('work-skill', Object.assign({}, defaultQueryParams, queryParams));
    },
    fetchWorkSkills: function fetchWorkSkills() {
      var queryParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var defaultQueryParams = {};

      return this.get('store').query('work-skill', Object.assign({}, defaultQueryParams, queryParams));
    }
  });
});