define('app-ember/adapters/application', ['exports', 'ember-authenticate-me/adapters/authenticated', 'app-ember/config/environment', 'app-ember/adapters/invitation-expired-error', 'app-ember/adapters/invitation-accepted-error'], function (exports, _authenticated, _environment, _invitationExpiredError, _invitationAcceptedError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticated.default.extend({
    namespace: _environment.default.apiNamespace || 'api/v1',
    coalesceFindRequests: true,

    handleResponse: function handleResponse(status, headers, payload) {
      if (403 === status) {
        switch (Ember.get(payload, 'reason')) {
          case 'accepted':
            return new _invitationAcceptedError.default();
          case 'expired':
            return new _invitationExpiredError.default();
        }
      }
      return this._super.apply(this, arguments);
    }
  });
});