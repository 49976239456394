define('app-ember/components/ws-topic-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['btn', 'btn-sm', 'badge-margin']
  });
});