define('app-ember/routes/member-survey', ['exports', 'ember-authenticate-me/mixins/routes/authenticated'], function (exports, _authenticated) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service('session'),

    beforeModel: function beforeModel() /*transition*/{
      var session = this.get('session');

      return (0, _authenticated.isAuthenticated)(session, this.get('sessionLifecycle')).catch(function () {
        // Don't care if not logged in
      });
    },

    model: function model(_ref) {
      var _this = this;

      var profileUuid = _ref.profileUuid;

      var profile = this.get('store').queryRecord('profile-member', {
        filters: {
          profile_uuid: profileUuid
        }
      });

      return Ember.RSVP.resolve(profile).then(function (resolvedProfile) {
        return _this.get('store').createRecord('three-week-survey', {
          profileMember: resolvedProfile,
          numberOfStories: resolvedProfile.get('stories.length'),
          jobOfferDetails: null,
          jobAcceptanceDetails: null
        });
      });
    }
  });
});