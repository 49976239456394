define('app-ember/serializers/invitation', ['exports', 'active-model-adapter'], function (exports, _activeModelAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = _activeModelAdapter.ActiveModelSerializer.extend({
    attrs: {
      organizationTitle: { serialize: false },
      requestorName: { serialize: false },
      createdAt: { serialize: false },
      updatedAt: { serialize: false }
    },

    serialize: function serialize(recordSnapshot /*, options*/) {
      var json = this._super.apply(this, arguments);
      var password = get(recordSnapshot.record, 'password');
      var passwordConfirmation = get(recordSnapshot.record, 'passwordConfirmation');

      json.password = password;
      json.password_confirmation = passwordConfirmation;

      return json;
    }
  });
});