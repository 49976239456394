define('app-ember/initializers/initialize-ember-authenticate-me', ['exports', 'ember-authenticate-me/configuration', 'app-ember/config/environment'], function (exports, _configuration, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var initializer = {
        name: 'ember-authenticate-me',
        initialize: function initialize(application) {
            if (arguments[1]) {
                // Ember < 2.1
                application = arguments[1];
            }

            (0, _configuration.configure)(_environment.default.emberAuthenticateMe || {});
        }
    };

    exports.default = initializer;
});