define('app-ember/controllers/story-creation/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    storyCreation: Ember.inject.controller(),
    topics: Ember.computed.alias('storyCreation.topics'),
    storyNumber: Ember.computed.alias('storyCreation.storyNumber'),
    ordinalIndicator: Ember.computed.alias('storyCreation.ordinalIndicator'),
    session: Ember.inject.service('session'),
    currentProfile: Ember.computed.alias('session.content.currentUser.profileable'),

    actions: {
      skipPromptedStory: function skipPromptedStory(story) {
        var _this = this;

        return Ember.RSVP.resolve(story.get('surveyQuestionUserAnswer')).then(function (answerModel) {
          answerModel.set('skipped', true);

          return answerModel.save().then(function () {
            story.destroyRecord();
            _this.send('reloadRoute');
          });
        });
      },
      onTopicNext: function onTopicNext() {
        this.transitionToRoute('story-creation.more-attributes');
      },
      transitionToProfile: function transitionToProfile() {
        this.transitionToRoute('member.profile', this.get('currentProfile'));
      }
    }

  });
});