define('app-ember/routes/organization-member', ['exports', 'app-ember/routes/base-authenticated'], function (exports, _baseAuthenticated) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.MEMBER = undefined;
  var MEMBER = exports.MEMBER = "ProfileMember";

  exports.default = _baseAuthenticated.default.extend({
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser'),

    beforeModel: function beforeModel() {
      var _this = this;

      return this._super.apply(this, arguments).then(function () {
        return Ember.RSVP.resolve(_this.get('currentUser.profileable')).then(function (profileable) {
          if (profileable.get('constructor.modelName') === MEMBER) {
            return _this.transitionTo('/');
          }
        });
      });
    }
  });
});