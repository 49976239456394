define('app-ember/adapters/profile-member', ['exports', 'app-ember/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    fetchSuggestedInterviewQuestions: function fetchSuggestedInterviewQuestions(profileMember) {
      var _this = this;

      var url = this.buildURL('ProfileMember', profileMember.get('id')) + '/suggested_interview_questions';
      return this.ajax(url, 'GET').then(function (response) {
        return _this.store.pushPayload(response);
      });
    }
  });
});