define('app-ember/components/progress-bars/-bar', ['exports', 'ember-bootstrap-controls/components/progress-bars/-bar'], function (exports, _bar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _bar.default;
    }
  });
});