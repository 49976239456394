define('app-ember/routes/story-creation/sample-story', ['exports', 'app-ember/routes/base-authenticated'], function (exports, _baseAuthenticated) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseAuthenticated.default.extend({
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser'),

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('currentExampleIndex', this.get('currentUser.profileable.stories.length'));
    }
  });
});