define('app-ember/components/ws-cloud-multi-select', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['ws-cloud-multi-select'],

    actions: {
      deselectOption: function deselectOption(option) {
        this.get('selected').removeObject(option);
      },
      selectOption: function selectOption(option) {
        this.get('selected').pushObject(option);
      }
    }
  });
});