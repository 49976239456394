define('app-ember/controllers/video-builder/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser'),

    actions: {
      onNext: function onNext() {
        this.transitionToRoute('video-builder.step-2');
      },
      onBack: function onBack() {
        this.transitionToRoute('member.profile.video', this.get('currentUser'));
      }
    }
  });
});