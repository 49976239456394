define('app-ember/templates/sign-up', ['exports', 'ember-authenticate-me/templates/sign-up'], function (exports, _signUp) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _signUp.default;
    }
  });
});