define('app-ember/routes/expired-invitation', ['exports', 'ember-authenticate-me/mixins/routes/unauthenticate-user'], function (exports, _unauthenticateUser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticateUser.default, {
    model: function model(params) {
      return this.store.findRecord('invitation', params.invitation_id);
    }
  });
});