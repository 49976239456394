define('app-ember/services/model-entities/survey-question-user-answers', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service('store'),

    fetchSurveyQuestionUserAnswers: function fetchSurveyQuestionUserAnswers(queryParams) {
      return this.get('store').query('survey-question-user-answer', queryParams);
    }
  });
});