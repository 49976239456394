define('app-ember/routes/story-creation/index', ['exports', 'app-ember/routes/base-authenticated'], function (exports, _baseAuthenticated) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseAuthenticated.default.extend({
    session: Ember.inject.service('session'),

    model: function model() {
      return this.modelFor('story-creation');
    },


    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('currentUser', this.get('session.content.currentUser'));
    }
  });
});