define('app-ember/models/employment-detail', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    profileMember: _emberData.default.belongsTo('profile-member', { async: true, inverse: 'employmentDetails' }),

    employer: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    startMonth: _emberData.default.attr('string'),
    endMonth: _emberData.default.attr('string'),

    startYear: _emberData.default.attr('number'),
    endYear: _emberData.default.attr('number'),
    yearsWorked: _emberData.default.attr('number'),
    monthsWorked: _emberData.default.attr('number'),

    currentlyEmployed: _emberData.default.attr('boolean'),

    startDate: _emberData.default.attr('date'),

    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),

    amountWorked: Ember.computed('startMonth', 'endMonth', 'startYear', 'endYear', 'currentlyEmployed', function () {
      var startDate = (0, _moment.default)('' + this.get('startMonth') + this.get('startYear') + '1', 'MMMYYYYD');
      var endDate = null;

      if (this.get('currentlyEmployed')) {
        endDate = (0, _moment.default)(new Date());
      } else {
        endDate = (0, _moment.default)('' + this.get('endMonth') + this.get('endYear') + '1', 'MMMYYYYD');
      }

      var duration = _moment.default.duration(endDate.diff(startDate));
      var baseMonths = Math.round(duration.asMonths());
      var months = baseMonths % 12;
      var years = Math.floor(baseMonths / 12);
      var yearLabel = years > 1 ? 'years' : 'year';
      var monthLabel = months > 1 ? 'months' : 'month';
      if (years >= 1) {
        if (months >= 1) {
          return years + ' ' + yearLabel + ', ' + months + ' ' + monthLabel;
        } else {
          return years + ' ' + yearLabel;
        }
      } else {
        return months + ' ' + monthLabel;
      }
    })
  });
});