define('app-ember/models/work-skill', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    customCreatorProfile: _emberData.default.belongsTo('profile-member', { async: true, inverse: 'customWorkSkills' }),
    profileMembers: _emberData.default.hasMany('profile-member', { async: true }),

    label: _emberData.default.attr('string'),

    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date')
  });
});