define('app-ember/components/bootstrap/control-wrapper', ['exports', 'ember-bootstrap-controls/components/bootstrap/control-wrapper'], function (exports, _controlWrapper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _controlWrapper.default;
    }
  });
});