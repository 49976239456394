define('app-ember/utils/extensions/jquery', ['exports', 'ember-bootstrap-controls/utils/extensions/jquery'], function (exports, _jquery) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _jquery.default;
    }
  });
});