define('app-ember/components/public-display/stories/v2-panel', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    stories: null,

    storyTopics: null,
    storyQualities: null,

    selectedFilterTopics: null,
    selectedFilterQualities: null,

    // actions
    onTopicClick: null,
    onQualityClick: null,

    expandedIndex: null,
    expandedStory: null,

    actions: {
      resetFilters: function resetFilters() {
        return this.setProperties({
          selectedFilterTopics: null,
          selectedFilterQualities: null,
          expandedIndex: null,
          expandedStory: null
        });
      }
    }
  });
});