define('app-ember/routes/admin', ['exports', 'app-ember/routes/base-authenticated'], function (exports, _baseAuthenticated) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.SITE_ADMIN = undefined;
  var SITE_ADMIN = exports.SITE_ADMIN = "WholeStory Site Admin";

  exports.default = _baseAuthenticated.default.extend({
    session: Ember.inject.service('session'),

    beforeModel: function beforeModel() {
      var _this = this;

      return this._super.apply(this, arguments).then(function () {
        return Ember.RSVP.resolve(_this.get('session.content.currentUser.isSiteAdmin')).then(function (isSiteAdmin) {
          if (!isSiteAdmin) {
            return _this.transitionTo('index');
          }
        });
      });
    }
  });
});