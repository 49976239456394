define('app-ember/services/model-entities/invitations', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service('store'),

    newInvitation: function newInvitation() {
      var properties = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var defaultProperties = {
        expiresAt: (0, _moment.default)().add(14, 'days')
      };

      return this.get('store').createRecord('invitation', Object.assign({}, defaultProperties, properties));
    },
    fetchInvitation: function fetchInvitation(id) {
      return this.get('store').find('invitation', id);
    },
    fetchInvitations: function fetchInvitations() {
      var queryParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var defaultQueryParams = {};

      return this.get('store').query('invitation', Object.assign({}, defaultQueryParams, queryParams));
    }
  });
});