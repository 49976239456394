define('app-ember/controllers/welcome/info-3', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		actions: {
			next: function next(route) {
				this.transitionToRoute(route);
			}
		}
	});
});