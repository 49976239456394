define('app-ember/components/freestyle/bootstrap-checkbox', ['exports', 'ember-bootstrap-controls/components/freestyle/bootstrap-checkbox'], function (exports, _bootstrapCheckbox) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _bootstrapCheckbox.default;
    }
  });
});