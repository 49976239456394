define('app-ember/components/ws-pagination', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['ml-auto', 'd-flex', 'flex-row', 'align-items-end', 'align-items-baseline'],
    firstItemIndex: Ember.computed('pageSize', 'pageNumber', function () {
      return 1 + (this.get('pageNumber') - 1) * this.get('pageSize');
    }),
    lastItemIndex: Ember.computed('pageSize', 'pageNumber', 'totalRecords', function () {
      return Math.min(this.get('pageSize') * this.get('pageNumber'), this.get('totalRecords'));
    }),
    actions: {
      changePageSize: function changePageSize(selected) {
        this.set('pageNumber', 1);
        return this.set('pageSize', selected);
      }
    }
  });
});