define('app-ember/components/ws-nav-bar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser'),
    organization: Ember.computed.alias('currentUser.profileable.organization'),

    actions: {
      logout: function logout() {
        this.transistionTo('logout');
      },
      showProfile: function showProfile() {
        this.transistionTo('member.profile', this.get('currentUser.id'));
      }
    }
  });
});