define('app-ember/routes/member/profile/education-and-skills', ['exports', 'app-ember/routes/base-authenticated'], function (exports, _baseAuthenticated) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseAuthenticated.default.extend({
    model: function model() {
      return this.modelFor('member/profile');
    },


    afterModel: function afterModel(model) {
      var _this = this;

      this._super.apply(this, arguments);
      return Ember.RSVP.resolve(model.get('profileable')).then(function (profile) {
        return Ember.RSVP.hash({
          profile: profile,
          educationDetails: profile.get('educationDetails'),
          workSkills: profile.get('workSkills')
        }).then(function (hash) {
          _this.set('userProfile', hash.profile);
          _this.set('workSkills', hash.workSkills);
          _this.set('educationDetails', hash.educationDetails);
          return model;
        });
      });
    },

    setupController: function setupController(controller /*, model*/) {
      this._super.apply(this, arguments);

      controller.setProperties({
        userProfile: this.get('userProfile'),
        workSkills: this.get('workSkills'),
        educationDetails: this.get('educationDetails')
      });
    }
  });
});