define('app-ember/controllers/admin/recruiters', ['exports', 'ember-light-table'], function (exports, _emberLightTable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['pageNumber', 'pageSize', "sortCriteria", "sortReverse", "organizationFilterId"],
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser'),
    media: Ember.inject.service(),

    pageNumber: 1,
    pageSize: 25,
    totalRecords: null,
    currentIterationCount: null,

    sortCriteria: "id",
    sortReverse: false,

    organizations: null,
    organizationFilterId: null,
    organizationFilterLabel: null,

    cellWidth: Ember.computed('media', function () {
      var media = this.get('media');

      if (media.get('isMobile')) {
        return '9rem';
      } else {
        return 'null';
      }
    }),

    columns: Ember.computed(function () {
      return [{
        label: 'Recruiter',
        valuePath: 'fullName',
        cellComponent: 'table-cell/org-user-profile-link',
        width: this.get('cellWidth')
      }, {
        label: 'Email',
        valuePath: 'email',
        width: this.get('cellWidth')
      }, {
        label: 'Status',
        valuePath: 'status',
        width: this.get('cellWidth')
      }, {
        label: '',
        valuePath: 'status',
        sortable: false,
        cellComponent: 'table-cell/activate-block-toggle',
        width: this.get('cellWidth')
      }];
    }),

    table: Ember.computed('model', function () {
      return new _emberLightTable.default(this.get('columns'), this.get('model').toArray(), { enableSync: true });
    }),

    actions: {
      onColumnSort: function onColumnSort(column) {
        this.set('sortCriteria', column.get('valuePath'));
        this.set('sortReverse', column.ascending ? false : true);
      },
      updateOrganizationFilter: function updateOrganizationFilter(selected) {
        this.set('organizationFilterId', selected.get('id'));
        this.set('organizationFilterLabel', selected);

        return this.set('pageNumber', 1);
      },
      resetFilters: function resetFilters() {
        this.set('pageNumber', 1);
        this.set('organizationFilterLabel', null);
        this.set('organizationFilterId', null);
      }
    }
  });
});