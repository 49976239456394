define('app-ember/serializers/interview-questions', ['exports', 'active-model-adapter'], function (exports, _activeModelAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _activeModelAdapter.ActiveModelSerializer.extend({
    attrs: {
      isStoryBasedQuestion: { serialize: false },
      isProfileBasedQuestion: { serialize: false }
    }

  });
});