define('app-ember/controllers/freestyle', ['exports', 'ember-freestyle/controllers/freestyle'], function (exports, _freestyle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject;
  exports.default = _freestyle.default.extend({
    emberFreestyle: inject.service(),

    selectedTopic: null,

    /* BEGIN-FREESTYLE-USAGE fp:notes
    ### A few notes regarding freestyle-palette
    - Accepts a colorPalette POJO like the one found in the freestyle.js blueprint controller
    - Looks very nice
    And another thing...
    ###### Markdown note demonstrating prettified code
    ```
    import Ember from 'ember';
    export default Ember.Component.extend({
    // ...
    colorPalette: {
      'primary': {
        'name': 'cyan',
        'base': '#00bcd4'
      },
      'accent': {
        'name': 'amber',
        'base': '#ffc107'
      }
    }
    // ...
    });
    ```
    END-FREESTYLE-USAGE */

    attributeList: [{ label: 'marriage' }, { label: 'death' }, { label: 'family' }, { label: 'health' }, { label: 'illness' }, { label: 'academics' }, { label: 'surgery' }, { label: 'mental-health' }, { label: 'dental' }, { label: 'race' }, { label: 'athletics' }, { label: 'height' }],

    selectedOptions: [],

    isTestPanelOpen: false,

    colorPalette: {
      'primary': {
        'name': 'purple',
        'base': '#99177e'
      },
      'secondary': {
        'name': 'yellow',
        'base': '#febe10'
      },
      'warning': {
        'name': 'yellow-light',
        'base': '#FFECBC'
      },
      'terciary': {
        'name': 'Cyan',
        'base': '#33b2c1'
      },
      'info': {
        'name': 'cyan-light',
        'base': '#B2E2E9'
      },
      'alert': {
        'name': 'Alert',
        'base': '#D9534F'
      },
      'tanFont': {
        'name': 'TanFont',
        'base': '#918A7D'
      },
      'tanDark': {
        'name': 'TanDark',
        'base': '#dfd5c8'
      },
      'tan': {
        'name': 'Tan',
        'base': '#e7dfd6'
      },
      'tanLight': {
        'name': 'TanLight',
        'base': '#efeae3'
      },
      'tanLighter': {
        'name': 'TanLighter',
        'base': '#FCFBFA'
      },
      'background': {
        'name': 'AppBackground',
        'base': '#FAF7F4'
      }
    },

    mockEmploymentDetails: [{
      employer: "Fancy High School",
      title: "Janitor",
      yearsWorked: 5,
      monthsWorked: 8,
      description: "Cleaned up stuff."
    }, {
      employer: "Large Reginal Medical Complex",
      title: "Night Janitor",
      yearsWorked: 3,
      monthsWorked: 1,
      description: "I cleaned up stuff, but no one wanted me seen."
    }, {
      employer: "Intelligence Agency",
      title: "Top Secret Janitor",
      yearsWorked: 12,
      monthsWorked: 1,
      description: "I was basically the shiny floor spy"
    }, {
      employer: "Home Depot",
      title: "Loss Prevention Specialist",
      yearsWorked: 3,
      monthsWorked: 5,
      description: "Caught thieves, just like one of the police"
    }, {
      employer: "Clean Buckets 4 Life",
      title: "Owner",
      yearsWorked: 2,
      monthsWorked: 0,
      description: "Gave people clean buckets"
    }, {
      employer: "Brakebills South",
      title: "Hedge Wizard",
      yearsWorked: 3,
      monthsWorked: 11,
      description: "Granted wishes and curses to unsuspecting travelers"
    }, {
      employer: "US Government",
      title: "Press Secratary",
      yearsWorked: 0,
      monthsWorked: 4,
      description: "Basically, I was on fire...all the time."
    }, {
      employer: "Target",
      title: "Cashier",
      yearsWorked: 4,
      monthsWorked: 0,
      description: "I rang up the people."
    }],

    actions: {
      noop: function noop() {
        alert("Alert from action 'noop' in freestyle.js");
      }
    }
  });
});