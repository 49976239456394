define('app-ember/components/ws-story-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    isEditing: null,
    storyIndex: null,
    storyNumber: null,
    actions: {
      toggleEdit: function toggleEdit() {
        this.toggleProperty('isEditing');
        console.log("toggling isEditing", this.get("isEditing"));
      }
    }

  });
});