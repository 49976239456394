define('app-ember/controllers/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    tagName: '',

    actions: {
      next: function next(route) {
        this.transitionToRoute(route);
      }
    }

  });
});