define('app-ember/components/bootstrap/tables/body/row/-columns', ['exports', 'ember-bootstrap-controls/components/bootstrap/tables/body/row/-columns'], function (exports, _columns) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _columns.default;
    }
  });
});