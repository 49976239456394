define('app-ember/routes/video-builder/step-2', ['exports', 'app-ember/routes/base-authenticated'], function (exports, _baseAuthenticated) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseAuthenticated.default.extend({
    store: Ember.inject.service('store'),
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser'),
    selectedStory: null,

    model: function model() {
      return this.get('store').query('story-v2', {
        filters: {
          profile_id: this.get('currentUser.profileable.id'),
          published: true
        }
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set('selectedStory', model.get('firstObject'));
    }
  });
});