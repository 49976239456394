define('app-ember/models/organization', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    primaryContactProfile: _emberData.default.belongsTo('profile-business', { async: true, inverse: 'organizationContactRole' }),

    profileBusinesses: _emberData.default.belongsTo('profile-business', { async: true }),
    profileMembers: _emberData.default.belongsTo('profile-member', { async: true }),

    jobs: _emberData.default.hasMany('job', { async: true }),
    clients: _emberData.default.hasMany('client', { async: true }),

    label: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),

    address1: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    zipCode: _emberData.default.attr('string'),
    videoFeatureEnabled: _emberData.default.attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),

    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),

    joinedOn: Ember.computed('createdAt', function () {
      return (0, _moment.default)(this.get('createdAt')).format('MMM Do YYYY');
    })
  });
});