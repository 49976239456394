define('app-ember/components/candidate/story-primer-result-table', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    userProfile: null,
    answerModels: null,

    lastTopicIndex: Ember.computed('userProfile.stories', function () {
      return this.get('userProfile.stories.length') - 1;
    })
  });
});