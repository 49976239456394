define('app-ember/controllers/video-builder/step-4', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser'),

    actions: {
      onNext: function onNext() {
        this.transitionToRoute('member.profile.video', this.get('currentUser'));
      },
      onBack: function onBack() {
        var _this = this;

        if (window.confirm('This will remove your current video. Are you sure you want to re-record?')) {
          var currentUser = this.get('currentUser');
          currentUser.set('muxVideoId', null);
          return currentUser.save().then(function () {
            return _this.transitionToRoute('video-builder.step-3');
          });
        }
      }
    }
  });
});