define('app-ember/controllers/admin/strength-assessment/index', ['exports', 'ember-light-table'], function (exports, _emberLightTable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['pageNumber', 'pageSize', 'sortCriteria', 'sortReverse'],
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser'),
    media: Ember.inject.service(),

    selectedOrganizations: [],
    pageNumber: 1,
    pageSize: 15,
    totalRecords: null,
    currentIterationCount: null,

    sortCriteria: "id",
    sortReverse: false,

    cellWidth: Ember.computed('media', function () {
      var media = this.get('media');

      if (media.get('isMobile')) {
        return '9rem';
      } else {
        return 'null';
      }
    }),

    actions: {
      onColumnSort: function onColumnSort(column) {
        this.set('sortCriteria', column.get('valuePath'));
        this.set('sortReverse', column.ascending ? false : true);
      }
    },

    columns: Ember.computed(function () {
      return [{
        label: 'Title',
        valuePath: 'title',
        cellComponent: 'table-cell/survey-question-link',
        width: this.get('cellWidth')
      }, {
        label: 'Example Sentence',
        valuePath: 'definition',
        sortable: false,
        width: this.get('cellWidth')
      }, {
        label: 'Prompt',
        valuePath: 'question',
        sortable: false,
        width: this.get('cellWidth')
      }];
    }),

    table: Ember.computed('model', function () {

      var model = this.get('model').toArray();

      var table = new _emberLightTable.default(this.get('columns'), model, { enableSync: true });

      return table;
    })

  });
});