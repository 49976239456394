define('app-ember/helpers/parse-columns', ['exports', 'ember-bootstrap-controls/helpers/parse-columns'], function (exports, _parseColumns) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _parseColumns.default;
    }
  });
  Object.defineProperty(exports, 'parseColumns', {
    enumerable: true,
    get: function () {
      return _parseColumns.parseColumns;
    }
  });
});