define('app-ember/components/ws-strength-picker', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'form',
    media: Ember.inject.service(),

    options: Ember.computed('min', 'max', function () {
      var start = this.get('min');
      var end = this.get('max');
      return Array(end - start + 1).fill().map(function (item, index) {
        return start + index;
      });
    }),

    actions: {
      selectStrength: function selectStrength(value) {
        this.set('value', value);
      }
    }
  });
});