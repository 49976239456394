define('app-ember/components/public-display/printable-interview-tips', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    candidate: null,
    currentJobCandidates: null,
    stories: null,
    suggestedInterviewQuestions: null,
    filterProfileTraits: null,
    hasStoryBasedQuestions: null

  });
});