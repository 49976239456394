define('app-ember/components/freestyle-prop-types/-one-of', ['exports', 'ember-bootstrap-controls/components/freestyle-prop-types/-one-of'], function (exports, _oneOf) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _oneOf.default;
    }
  });
});