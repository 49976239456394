define('app-ember/components/freestyle/ws-story-primer-card', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    questionAnswerModels: [],
    isSaving: true,

    actions: {
      completeEvaluation: function completeEvaluation() {}
    }
  });
});