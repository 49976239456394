define('app-ember/components/generic/collapsible-panel', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    title: null,
    content: null,
    story: null,
    isCollapsed: null,
    isExpanded: Ember.computed.not('isCollapsed'),

    index: null,
    expandedIndex: null,
    expandedStory: null,

    actions: {
      toggle: function toggle(index) {
        if (this.get('expandedIndex') !== index) {
          this.set('expandedIndex', index);
          this.set('expandedStory', this.get('story'));
        } else {
          this.set('expandedIndex', null);
          this.set('expandedStory', null);
        }
      }
    }
  });
});