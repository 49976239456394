define('app-ember/components/ws-blank-user-nav-bar', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    routing: Ember.inject.service('-routing'),
    router: Ember.inject.service('router'),
    notify: Ember.inject.service('notify'),
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser'),

    fullScreen: Ember.computed('routing.currentRouteName', function () {
      var currentRoute = this.get('routing.currentRouteName');
      return currentRoute.match(/public.profile.*/);
    }),

    publicProfileUrl: Ember.computed(function () {
      var user = this.get('model');

      if (user.get('profileable.profileUuid')) {
        return window.location.href;
      } else {
        return "N/A";
      }
    }),

    actions: {
      copySuccess: function copySuccess() {
        this.get('notify').success('Public URL copied to clipboard.');
      },
      copyError: function copyError() {
        this.get('notify').alert('Error with copying your public URL.');
      }
    }

  });
});