define('app-ember/components/table-cell/organization-link', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    value: null,
    row: null,
    column: null
  });
});