define('app-ember/routes/organization/show/invitations/index', ['exports', 'app-ember/routes/base-authenticated', 'ember-bootstrap-controls/mixins/routes/pagination'], function (exports, _baseAuthenticated, _pagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseAuthenticated.default.extend(_pagination.default, {
    invitationEntity: Ember.inject.service('model-entities/invitations'),
    queryParams: {
      pageNumber: { refreshModel: true },
      pageSize: { refreshModel: true },
      sortCriteria: { refreshModel: true },
      sortReverse: { refreshModel: true },
      statusesFilter: { refreshModel: true }
    },

    model: function model(params) {
      var org = this.modelFor('organization/show');

      return this.get('invitationEntity').fetchInvitations({
        page: this.paginationParams(params),
        filters: {
          organization_id: org.get('id'),
          status: params.statusesFilter
        },
        sort: {
          criteria: params.sortCriteria,
          reverse: params.sortReverse
        }
      });
    },


    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);
    },

    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.setProperties({
        organization: this.modelFor('organization/show')
      });
    },

    actions: {
      loading: function loading() {
        return true;
      }
    }
  });
});