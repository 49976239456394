define('app-ember/models/job-suggestion', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    invitation: _emberData.default.belongsTo('invitation', { async: true }),
    profileBusiness: _emberData.default.belongsTo('profile-business', { async: true }),
    job: _emberData.default.belongsTo('job', { async: true }),

    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date')
  });
});