define('app-ember/models/uploaded-cv', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    profileMember: _emberData.default.belongsTo('profile-member', { async: true, inverse: 'uploadedCv' }),

    label: _emberData.default.attr('string'),
    s3Key: _emberData.default.attr('string'),
    errorMessage: _emberData.default.attr('string'),

    documentVerified: _emberData.default.attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),

    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),

    reloadUntilDocumentProcessed: function reloadUntilDocumentProcessed() {
      var _this = this;

      if (!this.get('documentVerified') && !this.get('errorMessage')) {
        Ember.run.later(function () {
          _this.reload();
          _this.reloadUntilDocumentProcessed();
        }, 5000);
      }
    },


    uuid: Ember.computed('s3Key', function () {
      return '' + this.get('s3Key');
    }),

    download: Ember.computed('s3Key', function () {
      var adaptor = this.get('store').adapterFor('uploaded-cv');
      var file = this;

      if (file.get('id')) {
        var url = [adaptor.get('namespace'), adaptor.pathForType('uploaded-cv'), '' + this.get('id'), 'download_url'].join('/');

        return _emberData.default.PromiseObject.create({
          promise: adaptor.ajax('/' + url).then(function (json) {
            return { url: json.download_url }; // jscs:ignore requireCamelCaseOrUpperCaseIdentifiers
          })
        });
      } else {
        return null;
      }
    }),

    humanErrorMessage: Ember.computed('errorMessage', function () {
      var unenclosedError = 'Error: Unclosed tag :';

      if (this.get('errorMessage').indexOf(unenclosedError) > -1) {
        var error = this.get('errorMessage').substring(this.get('errorMessage').indexOf(unenclosedError) + unenclosedError.length);
        return 'Broken tag ' + error;
      }

      if (this.get('errorMessage').indexOf('Invalid file type') > -1 || this.get('errorMessage').indexOf('Error: Corrupted zip') > -1) {
        return 'Error: Invalid File Type';
      } else {
        return this.get('errorMessage');
      }
    })
  });
});