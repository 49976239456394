define('app-ember/components/organization-action-menu', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    notify: Ember.inject.service('notify'),
    secondBtnLabel: null,
    isAdmin: null,

    actions: {}
  });
});