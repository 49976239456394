define('app-ember/components/story-creation/self-start-loop/story-content-slide', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    story: null,
    maxNumberOfCharacters: 500,
    selectedAttributes: null,
    storyNumber: null,
    selectedTopic: null,

    numberOfCharactersLeft: Ember.computed('story.description', function () {
      var charactersUsed = this.get('story.description.length');
      if (charactersUsed) {
        return this.get('maxNumberOfCharacters') - charactersUsed;
      } else {
        return this.get('maxNumberOfCharacters');
      }
    })
  });
});