define('app-ember/controllers/organization/show/invitations/index', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['pageNumber', 'pageSize', "sortCriteria", "sortReverse", "statusesFilter"],
    router: Ember.inject.service('router'),
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser'),
    pageNumber: 1,
    pageSize: 15,
    isDeleting: false,
    invitationToDelete: null,
    nonOwnerRecruiter: false,
    deleteMessage: "Deleting this invitation will remove the ability for the invitee to create an account. Would you like to continue?",
    nonRequesterDeleteMessage: "You can only delete invitations that were created from your account.",

    sortCriteria: "id",
    sortReverse: false,

    currentStatusFilter: null,
    totalRecords: null,
    currentIterationCount: null,

    publicInviteUrl: Ember.computed(function () {
      var uri = this.get('router').urlFor("self-signup.index.info", {
        queryParams: { organization_id: this.get('organization.id') }
      });
      return window.location.href.split('/organization/show')[0] + ('' + uri);
    }),

    actions: {
      updateStatusFilter: function updateStatusFilter(options) {
        this.set('currentStatusFilter', options);
        this.set('statusesFilter', options);
        this.set('pageNumber', 1);
      },
      reinvite: function reinvite(invitation) {
        var _this = this;

        invitation.setProperties({
          'requestor': this.get('currentUser'),
          'expiresAt': (0, _moment.default)().add(14, 'days').toDate(),
          'reinvite': true
        });

        return invitation.save().then(function () {
          return _this.notify.success('Reinvitation sent.');
        });
      },
      deleteWarning: function deleteWarning(invitation, user) {
        if (user.get('profileable.role.label') === "Recruiter" && invitation.get('requestor.id') !== user.get('id')) {
          this.set('nonOwnerRecruiter', true);
        } else {
          this.set('invitationToDelete', invitation);
        }
        return this.set('isDeleting', true);
      },
      cancelDelete: function cancelDelete() {
        this.set('nonOwnerRecruiter', false);
        this.set('invitationToDelete', null);
        return this.set('isDeleting', false);
      },
      delete: function _delete(invitation) {
        var _this2 = this;

        this.set('isDeleting', false);
        this.set('nonOwnerRecruiter', false);
        this.set('invitationToDelete', null);
        return invitation.destroyRecord().then(function () {
          return _this2.notify.success('Invitation deleted.');
        });
      },
      copySuccess: function copySuccess() {
        this.get('notify').success('Invite URL copied to clipboard.');
      },
      copyError: function copyError() {
        this.get('notify').alert('Error with copying your invite URL.');
      }
    }
  });
});