define('app-ember/routes/organization/show/invitations/new', ['exports', 'app-ember/routes/base-authenticated', 'app-ember/models/role'], function (exports, _baseAuthenticated, _role) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseAuthenticated.default.extend({
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser'),
    rolesEntity: Ember.inject.service('model-entities/roles'),
    jobsEntity: Ember.inject.service('model-entities/jobs'),

    afterModel: function afterModel(model) {
      var _this = this;

      var labels = [];

      return Ember.RSVP.resolve(this.get('currentUser')).then(function (currentUser) {
        if (currentUser.get('isOrgAdmin')) {
          labels.pushObject(_role.RECRUITER);

          return Ember.RSVP.hash({
            availableRoles: _this.get('rolesEntity').fetchRoles({
              filters: {
                labels: labels
              }
            }),
            jobs: null
          });
        } else {
          var organization = _this.modelFor('organization/show');
          labels.pushObject(_role.MEMBER);

          return Ember.RSVP.hash({
            availableRoles: _this.get('rolesEntity').fetchRoles({
              filters: {
                labels: labels
              }
            }),
            jobs: _this.get('jobsEntity').fetchJobs({
              filters: {
                organization_id: organization.get('id'),
                unarchived: true
              }
            })
          });
        }
      }).then(function (hash) {
        _this.set('jobs', hash.jobs);
        _this.set('roles', hash.availableRoles);
        return model;
      });
    },


    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.setProperties({
        roles: this.get('roles'),
        jobs: this.get('jobs'),
        organization: this.modelFor('organization/show'),
        selectedJobs: [],
        userOrganization: this.modelFor('organization/show')
      });
    },

    actions: {
      reloadRoute: function reloadRoute() {
        this.refresh();
      }
    }
  });
});