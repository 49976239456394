define('app-ember/controllers/member/profile/video', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    notify: Ember.inject.service('notify'),
    currentUser: Ember.computed.alias('session.content.currentUser'),
    organizationOption: Ember.computed('model.profileable.organization.label', function () {
      return 'Only visible to ' + this.get('model.profileable.organization.label');
    }),

    privacyOptions: Ember.computed('organizationOption', function () {
      if (Ember.isBlank(this.get('organizationOption'))) {
        return ['Private', 'Public'];
      } else {
        return ['Private', 'Public', this.get('organizationOption')];
      }
    }),

    currentPrivacySettings: Ember.computed('model.videoPrivacy', 'privacyOptions', {
      get: function get(key) {
        switch (this.get('model.videoPrivacy')) {
          case 'nobody':
            return 'Private';
          case 'organization':
            return this.get('organizationOption');
          case 'anyone':
            return 'Public';
        }
      },
      set: function set(key, value) {
        var model = this.get('model');
        switch (value) {
          case 'Private':
            model.set('videoPrivacy', 'nobody');
            break;
          case this.get('organizationOption'):
            model.set('videoPrivacy', 'organization');
            break;
          case 'Public':
            model.set('videoPrivacy', 'anyone');
            break;
        }

        return value;
      }
    }),

    actions: {
      updatePrivacy: function updatePrivacy(newSetting) {
        var model = this.get('model');
        this.set('currentPrivacySettings', newSetting);
        return model.save();
      },
      removeVideo: function removeVideo() {
        var _this = this;

        if (window.confirm('Are you sure you want to delete your video?')) {
          var model = this.get('model');
          model.set('muxVideoId', null);
          return model.save().then(function () {
            _this.get('notify').success('Your video was successfully deleted.');
          });
        }
      }
    }
  });
});