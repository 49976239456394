define('app-ember/components/jobs/form-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    organization: null,
    job: null,
    isOpen: null,
    isEditing: null,
    isSaving: null,

    maxNumberOfCharacters: 250,

    numberOfCharactersLeft: Ember.computed('job.description', function () {
      var charactersUsed = this.get('job.description.length');
      if (charactersUsed) {
        return this.get('maxNumberOfCharacters') - charactersUsed;
      } else {
        return this.get('maxNumberOfCharacters');
      }
    })
  });
});