define('app-ember/components/candidate/employment-information-display-panel', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    notify: Ember.inject.service('notify'),
    employmentModels: null,
    user: null,

    /* ACTIONS */
    updateEmployerInfo: null
  });
});