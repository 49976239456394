define("app-ember/controllers/welcome/story-primer-intro-2", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      next: function next() {
        this.transitionToRoute("welcome.story-primer-intro-continued");
      }
    }
  });
});