define('app-ember/transitions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    var sequenceStepsFunction = function sequenceStepsFunction(self, baseRoute, stepsArray) {
      for (var i = 1; i < stepsArray.length; i++) {
        self.transition(self.fromRoute(baseRoute + '.' + stepsArray[i - 1]), self.toRoute(baseRoute + '.' + stepsArray[i]), self.use('toLeft'), self.reverse('toRight'));
      }
    };
    //animations for video builder
    var videoBuilderSteps = ['index', 'step-2', 'step-3', 'step-4'];
    sequenceStepsFunction(this, 'video-builder', videoBuilderSteps);

    //animations for new user onboarding - account creation
    var onboardingSteps = ['info', 'info-1', 'info-2', 'create-account', 'create-account-1'];
    sequenceStepsFunction(this, 'onboarding.index', onboardingSteps);

    //animations for new user self-signup - account creation
    var selfSignupSteps = ['info', 'info-1', 'info-2', 'create-account', 'create-account-1'];
    sequenceStepsFunction(this, 'self-signup.index', selfSignupSteps);

    //animations for welcome to app section
    var welcomeSteps = ['edu-1', 'edu-2', 'edu-3', 'self-eval-intro', 'story-primer-intro-2', 'story-primer-intro-continued'];
    sequenceStepsFunction(this, 'welcome', welcomeSteps);

    this.transition(this.fromRoute('welcome.story-primer-intro-continued'), this.toRoute('story-primer.index'), this.use('toLeft'), this.reverse('toRight'));

    //animations for story primer
    this.transition(this.toValue(function (oldValue, newValue) {
      return oldValue < newValue;
    }), this.use('toRight'), this.reverse('toLeft'));
    // Flippy card
    this.transition(this.hasClass('flippy-card'), this.toValue(true), this.use('toRight'), this.reverse('toLeft'));
    // Yay Card
    this.transition(this.hasClass('primer-finished'), this.toValue(true), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.fromRoute('self-eval.index'), this.toRoute('story-creation.story-intro'), this.use('toLeft'), this.reverse('toRight'));

    //animations for story creation section
    var storyBuilderSteps = ['index', 'more-attributes', 'story-cue1', 'story-cue2', 'story-cue3', 'achievement'];
    sequenceStepsFunction(this, 'story-creation', storyBuilderSteps);

    //animations for quick notes
    this.transition(this.fromRoute('story-creation'), this.toRoute('quick-note'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('quick-note'), this.toRoute('self-guided-story-builder.index'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('quick-note'), this.toRoute('story-creation'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('story-creation.story-intro'), this.toRoute('story-creation.sample-story'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('story-creation.sample-story'), this.toRoute('story-creation.index'), this.use('toLeft'), this.reverse('toRight'));
    // animations for loading
    this.transition(this.toRoute(['welcome', 'self-eval', 'story-creation', 'onboarding', 'self-signup']), this.fromRoute('loading'), this.use('toLeft'), this.reverse('toLeft'));

    this.transition(this.fromRoute(function (routeName) {
      return true;
    }), this.toRoute(function (routeName) {}), this.use('toUp'));
  };
});