define('app-ember/routes/public/profile', ['exports', 'ember-authenticate-me/mixins/routes/authenticated'], function (exports, _authenticated) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function fetchSuggestedInterviewQuestions(store, profile) {// TODO this will get changed with the final query guts in a later PR

  }

  exports.default = Ember.Route.extend({
    intercom: Ember.inject.service(),
    usersEntity: Ember.inject.service('model-entities/users'),
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser'),

    beforeModel: function beforeModel(transition) {
      var session = this.get('session');

      return (0, _authenticated.isAuthenticated)(session, this.get('sessionLifecycle')).catch(function () {
        // Don't care if not logged in
      });
    },

    model: function model(_ref) {
      var profileUuid = _ref.profileUuid;

      return this.get('usersEntity').fetchUserWithUuid({
        filters: {
          profile_uuid: profileUuid
        }
      });
    },


    afterModel: function afterModel(model) {
      var _this = this;

      return Ember.RSVP.resolve(model.get('profileable')).then(function (profile) {
        // TODO check to ensure that this is a member profile
        return Ember.RSVP.hash({
          profile: profile,
          employmentDetails: profile.get('employmentDetails'),
          v2Stories: _this.get('store').query('story-v2', {
            filters: {
              profile_id: profile.get('id'),
              published: true
            }
          }),
          suggestedInterviewQuestions: profile.fetchSuggestedInterviewQuestions()
        }).then(function (hash) {
          _this.set('userProfile', hash.profile);
          _this.set('employmentDetails', hash.employmentDetails);
          _this.set('v2Stories', hash.v2Stories);
          _this.set('suggestedInterviewQuestions', hash.suggestedInterviewQuestions);

          if (hash.v2Stories.get('length') && hash.v2Stories.get('length') > 0) {
            var storyTopics = [];
            var storyQualities = [];

            hash.v2Stories.forEach(function (story) {
              storyTopics.pushObject(story.get('topic'));
            });

            hash.v2Stories.forEach(function (story) {
              story.get('storyAttributes').forEach(function (quality) {
                storyQualities.pushObject(quality);
              });
            });

            _this.set('storyTopics', storyTopics.uniqBy('id'));
            _this.set('storyQualities', storyQualities.uniqBy('id'));
          } else {
            _this.set('storyTopics', null);
            _this.set('storyQualities', null);
          }
          var currentUrl = window.location.href;
          _this.get('intercom').set('user', {
            profile_type: _this.get('currentUser.profileable.role.label'),
            name: _this.get('currentUser.firstName') + ' ' + _this.get('currentUser.lastName'),
            email: _this.get('currentUser.email'),
            id: _this.get('currentUser.id'),
            created_at: _this.get('currentUser.createdAt')
          });
          _this.get('intercom').update(_this.get('intercom.user'));

          return _this.get('currentUser.profileable');
        }).then(function (currentUserProfile) {
          if (currentUserProfile && currentUserProfile.get('isRecruiter')) {
            return Ember.RSVP.hash({
              currentJobCandidates: _this.get('store').query('job-candidate', {
                filters: {
                  profile_member_id: profile.get('id'),
                  profile_business_id: _this.get('currentUser.profileable.id'),
                  unarchived: true
                }
              }),
              organizationId: _this.get('currentUser.profileable.organization.id')
            }).then(function (hash) {
              _this.set('currentJobCandidates', hash.currentJobCandidates);
              return _this.get('store').query('job', {
                filters: {
                  organization_id: hash.organizationId,
                  exclude_ids: hash.currentJobCandidates.map(function (j) {
                    return j.get('job.id');
                  }),
                  unarchived: true
                }
              });
            }).then(function (avaliableJobs) {
              _this.set('avaliableJobs', avaliableJobs);
              return model;
            });
          } else {
            _this.set('currentJobCandidates', []);
            _this.set('avaliableJobs', []);
            return model;
          }
        });
      });
    },

    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      if (this.get('currentUser')) {
        controller.set('currentUser', this.get('currentUser'));

        if (this.get('currentUser.id') === model.get('id')) {
          this.get('notify').success('What you see on your public profile is exactly the same as what other people see.', {
            closeAfter: 4000
          });
        }
      }

      controller.setProperties({
        userProfile: this.get('userProfile'),
        v2Stories: this.get('v2Stories'),
        suggestedInterviewQuestions: this.get('suggestedInterviewQuestions'),
        employmentDetails: this.get('employmentDetails'),
        currentJobCandidates: this.get('currentJobCandidates').toArray(),
        avaliableJobs: this.get('avaliableJobs').toArray(),
        storyTopics: this.get('storyTopics'),
        storyQualities: this.get('storyQualities')
      });
    },

    renderTemplate: function renderTemplate() {
      var _this2 = this;

      // Render default outlet
      this.render();
      // render extra outlets
      Ember.RSVP.resolve(this.get('currentUser.profileable')).then(function (currentUserProfile) {
        if (currentUserProfile && currentUserProfile.get('isRecruiter')) {
          _this2.render("public/tip-tray", {
            into: "application",
            outlet: "tray"
          });
        }
      });
    }
  });
});