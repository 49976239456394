define('app-ember/routes/self-signup/index/info-2', ['exports', 'ember-authenticate-me/mixins/routes/unauthenticate-user'], function (exports, _unauthenticateUser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticateUser.default, {
    model: function model() {
      return this.modelFor('self-signup.index');
    },
    afterModel: function afterModel(model) {
      var _this = this;

      this._super.apply(this, arguments);

      if (!Ember.isBlank(model.organization_id)) {
        return this.store.findRecord('organization', model.organization_id).then(function (organization) {
          _this.set('organization', organization);
        });
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('organization', this.get('organization'));
    }
  });
});