define('app-ember/components/ws-card/-centered-header', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['font-weight-bold', 'mt-2', 'mt-md-2', 'd-flex', 'flex-row', 'justify-content-center'],
    tagName: 'h3'
  });
});