define('app-ember/routes/onboarding/index/create-account', ['exports', 'ember-authenticate-me/mixins/routes/unauthenticate-user'], function (exports, _unauthenticateUser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticateUser.default, {
    model: function model() {
      var model = this.modelFor('onboarding.index');
      var store = this.get('store');
      var adapter = store.adapterFor('User');
      var autoGenUrl = '' + adapter.buildURL('User', 'generate_username');
      return adapter.ajax(autoGenUrl, 'GET').then(function (response) {
        model.set('username', Ember.get(response, 'username'));
        return model;
      }).catch(function (e) {
        return model;
        throw e;
      });
    }
  });
});