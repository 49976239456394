define('app-ember/controllers/member/profile/index', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    storyCreation: Ember.inject.controller(),
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser'),
    isSavingResume: false,
    favoritedStoriesCount: 0,
    saving: false,

    steps: Ember.computed('v1Stories.[]', function () {
      var steps = [{ setFontSize: 12 }];
      var v1Stories = this.get('v1Stories');
      var fixed = v1Stories.map(function (story) {
        return '\t' + story.get('pdfText') + '\n\n';
      });
      return steps.concat([{ text: [15, 20, fixed, { maxWidth: 180 }] }]);
    }),

    savePublishedStatus: function savePublishedStatus(story) {
      story.toggleProperty('published');
      if (story.get('favorite')) {
        story.set('favorite', false);
        this.set('favoritedStoriesCount', this.get('favoritedStoriesCount') - 1);
      }

      return story.save();
    },


    actions: {
      toggleFavoriteStory: function toggleFavoriteStory(story, newValue) {
        var _this = this;

        if (!this.get('saving')) {
          this.set('saving', true);
          story.set('favorite', newValue);
          return story.save().finally(function () {
            if (newValue) {
              _this.set('favoritedStoriesCount', _this.get('favoritedStoriesCount') + 1);
            } else {
              _this.set('favoritedStoriesCount', _this.get('favoritedStoriesCount') - 1);
            }
            _this.set('saving', false);
          });
        } else {
          return new Ember.RSVP.Promise(function (resolve, _) {
            resolve({});
          });
        }
      },
      togglePublishedStory: function togglePublishedStory(story) {
        var _this2 = this;

        var user = this.get('model');
        var stories = this.get('model.profileable.stories');

        if (!this.get('saving')) {
          this.set('saving', true);

          if (story.get('published') && stories.filterBy('published').length === 3) {
            if (window.confirm('Setting this story as a draft will revert your profile status to incomplete. Would you like to continue?')) {
              return this.savePublishedStatus(story).then(function () {
                user.set('completedProfile', false);
                user.set('profileCompletedAt', null);
                return user.save().then(function (savedUser) {
                  _this2.set('saving', false);
                  return savedUser.reload();
                });
              });
            }
          } else if (!story.get('published') && stories.filterBy('published').length === 2) {
            return this.savePublishedStatus(story).then(function () {
              user.set('completedProfile', true);
              user.set('profileCompletedAt', new Date());
              return user.save().then(function (savedUser) {
                _this2.set('saving', false);
                return savedUser.reload();
              });
            });
          } else {
            return this.savePublishedStatus(story).then(function () {
              return _this2.set('saving', false);
            });
          }
        } else {
          return new Ember.RSVP.Promise(function (resolve, _) {
            resolve({});
          });
        }
      },
      createTopicStory: function createTopicStory() {
        this.get('storyCreation').send('navigateToQuickNote', "to-topic");
      },
      createAttributeStory: function createAttributeStory() {
        this.get('storyCreation').send('navigateToQuickNote', "to-attribute");
      }
    }
  });
});