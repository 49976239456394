define("app-ember/controllers/story-creation/story-intro", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      onBegin: function onBegin() {
        this.transitionToRoute("story-creation.sample-story");
      }
    }
  });
});