define('app-ember/routes/self-guided-story-builder', ['exports', 'app-ember/routes/base-authenticated'], function (exports, _baseAuthenticated) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseAuthenticated.default.extend({
    store: Ember.inject.service('store'),
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser'),

    model: function model() {
      return this.get('store').createRecord('story-v2', {
        profileMember: this.get('currentUser.profileable'),
        storyAttributes: [],
        topic: null
      });
    },


    actions: {
      reloadRoute: function reloadRoute() {
        this.refresh();
      }
    }
  });
});