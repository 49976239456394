define('app-ember/components/freestyle/bootstrap-inputs/markdown', ['exports', 'ember-bootstrap-controls/components/freestyle/bootstrap-inputs/markdown'], function (exports, _markdown) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _markdown.default;
    }
  });
});