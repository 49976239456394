define('app-ember/components/bootstrap/tables/-action-bar-header', ['exports', 'ember-bootstrap-controls/components/bootstrap/tables/-action-bar-header'], function (exports, _actionBarHeader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _actionBarHeader.default;
    }
  });
});