define('app-ember/routes/self-eval/index', ['exports', 'app-ember/routes/base-authenticated'], function (exports, _baseAuthenticated) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseAuthenticated.default.extend({
    surveyQuestionsRepository: Ember.inject.service('model-entities/survey-questions'),
    surveyQuestionUserAnswersRepository: Ember.inject.service('model-entities/survey-question-user-answers'),

    model: function model() {
      return Ember.RSVP.hash({
        surveyQuestionUserAnswers: this.get('surveyQuestionUserAnswersRepository').fetchSurveyQuestionUserAnswers({
          filters: {
            profile_id: this.get('session.content.currentUser.profileable.id')
          },
          sort: {
            criteria: 'survey_question_id'
          }
        })

      }).then(function (hash) {
        return hash.surveyQuestionUserAnswers;
      });
    }
  });
});