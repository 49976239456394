define('app-ember/torii-adapters/application', ['exports', 'ember-authenticate-me/torii-adapters/base', 'app-ember/config/environment'], function (exports, _base, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SESSION_URI = _environment.default.emberAuthenticateMe.sessionUri || '/token_authenticate_me/api/v1/session';
  var SESSION_KEY = _environment.default.emberAuthenticateMe.sessionKey || 'token_authenticate_me/session';

  exports.default = _base.default.extend({
    sessionUri: SESSION_URI,
    sessionKey: SESSION_KEY
  });
});