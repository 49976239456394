define('app-ember/components/freestyle/bootstrap/tables/body/-row', ['exports', 'ember-bootstrap-controls/components/freestyle/bootstrap/tables/body/-row'], function (exports, _row) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _row.default;
    }
  });
});