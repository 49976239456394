define('app-ember/models/profile-member', ['exports', 'ember-data', 'app-ember/mixins/models/profileable'], function (exports, _emberData, _profileable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_profileable.default, {
    role: _emberData.default.belongsTo('role', { async: true }),
    organization: _emberData.default.belongsTo('organization', { async: true }),

    userLocation: _emberData.default.attr('string'),

    surveyQuestionUserAnswers: _emberData.default.hasMany('survey-question-user-answer', { async: true }),

    stories: _emberData.default.hasMany('story-v2', { async: true, inverse: 'profileMember' }),
    publishedStories: Ember.computed.filter('stories.@each.published', function (story, index, array) {
      return story.get('published');
    }),

    employmentDetails: _emberData.default.hasMany('employment-detail', { async: true, inverse: 'profileMember' }),
    educationDetails: _emberData.default.hasMany('education-detail', { async: true }),
    workSkills: _emberData.default.hasMany('work-skill', { async: true }),
    customWorkSkills: _emberData.default.hasMany('work-skill', { async: true, inverse: 'customCreatorProfile' }),
    jobCandidates: _emberData.default.hasMany('job-candidate', { async: true }),

    mostRecentEmployment: _emberData.default.belongsTo('employment-detail', { async: true, inverse: null }),
    uploadedCv: _emberData.default.belongsTo('uploaded-cv', { async: true, inverse: 'profileMember' }),
    threeWeekSurvey: _emberData.default.belongsTo('three-week-survey', { async: true, inverse: 'profileMember' }),

    profileUuid: _emberData.default.attr('string'),

    hasCompletedOnboarding: _emberData.default.attr('boolean'),
    completedProfile: _emberData.default.attr('boolean'),
    hasPublishedStories: Ember.computed.notEmpty('publishedStories'),

    profileCompletedAt: _emberData.default.attr('date'),
    firstStoryCompletedAt: _emberData.default.attr('date'),

    currentQuickNote: _emberData.default.attr('number'),

    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),

    fetchSuggestedInterviewQuestions: function fetchSuggestedInterviewQuestions() {
      var adapter = this.store.adapterFor('ProfileMember');
      return adapter.fetchSuggestedInterviewQuestions(this);
    }
  });
});