define('app-ember/services/model-entities/jobs', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service('store'),

    fetchJob: function fetchJob(id) {
      return this.get('store').find('job', id);
    },
    fetchSingleJob: function fetchSingleJob() {
      var queryParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var defaultQueryParams = {};

      return this.get('store').queryRecord('job', Object.assign({}, defaultQueryParams, queryParams));
    },
    fetchJobs: function fetchJobs() {
      var queryParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var defaultQueryParams = {};

      return this.get('store').query('job', Object.assign({}, defaultQueryParams, queryParams));
    }
  });
});