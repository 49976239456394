define('app-ember/routes/onboarding/index', ['exports', 'ember-authenticate-me/mixins/routes/unauthenticate-user', 'app-ember/adapters/invitation-accepted-error', 'app-ember/adapters/invitation-expired-error'], function (exports, _unauthenticateUser, _invitationAcceptedError, _invitationExpiredError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticateUser.default, {
    notify: Ember.inject.service('notify'),
    model: function model(params) {
      return this.store.findRecord('invitation', params.invitation_id);
    },
    afterModel: function afterModel(model) {
      if (model.get('expired')) {
        return this.transitionTo('expired-invitation', model.get('id'));
      } else if (model.get('alreadyAccepted')) {
        return this.transitionTo('login');
      }
    },


    actions: {
      error: function error(_error, transition) {
        if (_error instanceof _invitationAcceptedError.default) {
          this.get('notify').alert('That invitation has already been accepted.');
          this.replaceWith('login');
          return;
        } else if (_error instanceof _invitationExpiredError.default) {
          this.get('notify').alert('That invitation has expired.');
          this.replaceWith('login');
          return;
        }
      }
    }
  });
});