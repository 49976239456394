define('app-ember/routes/self-signup/index/info-1', ['exports', 'ember-authenticate-me/mixins/routes/unauthenticate-user'], function (exports, _unauthenticateUser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticateUser.default, {
    model: function model() {
      return this.modelFor('self-signup.index');
    }
  });
});