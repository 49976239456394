define('app-ember/controllers/admin/invitations', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['pageNumber', 'pageSize'],
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser'),
    pageNumber: 1,
    pageSize: 15,
    isDeleting: false,
    invitationToDelete: null,
    deleteMessage: "Deleting this invitation will remove the ability for the invitee to create an account. Would you like to continue?",
    totalRecords: null,
    currentIterationCount: null,

    actions: {
      reinvite: function reinvite(invitation) {
        var _this = this;

        invitation.setProperties({
          'requestor': this.get('currentUser'),
          'expiresAt': (0, _moment.default)().add(14, 'days').toDate(),
          'reinvite': true
        });

        return invitation.save().then(function () {
          return _this.notify.success('Reinvitation sent.');
        });
      },
      deleteWarning: function deleteWarning(invitation) {
        this.set('invitationToDelete', invitation);
        return this.set('isDeleting', true);
      },
      cancelDelete: function cancelDelete() {
        this.set('invitationToDelete', null);
        return this.set('isDeleting', false);
      },
      delete: function _delete(invitation) {
        var _this2 = this;

        this.set('isDeleting', false);
        this.set('nonOwnerRecruiter', false);
        this.set('invitationToDelete', null);
        return invitation.destroyRecord().then(function () {
          return _this2.notify.success('Invitation deleted.');
        });
      }
    }
  });
});