define('app-ember/components/progress-bar/-bar', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['progress'],
    barClass: 'bg-primary',
    completionWidth: Ember.computed('currentStep', 'totalSteps', function () {
      return Math.trunc(this.get('currentStep') / this.get('totalSteps') * 100);
    })
    // We can add animation by delaying this width fill
  });
});