define('app-ember/components/ws-topic-cloud', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["ws-topic-cloud"],
    topicList: null,
    collapsible: null,
    media: Ember.inject.service(),

    pillDisplayAmount: Ember.computed('topicList', function () {
      var topicList = this.get('topicList');

      if (this.get('media.isMobile')) {
        return 4;
      } else {
        if (topicList.get('length')) {
          return parseInt(topicList.get('length') / 2);
        } else {
          return 15;
        }
      }
    })
  });
});