define('app-ember/components/admin/member-search-result', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service('store'),

    stories: [],

    didReceiveAttrs: function didReceiveAttrs() {
      this.set('stories', this.get('store').query('story-v2', {
        filters: {
          profile_id: this.get('member.id'),
          published: true
        }
      }));
    }
  });
});