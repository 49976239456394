define('app-ember/controllers/login', ['exports', 'ember-authenticate-me/controllers/login'], function (exports, _login) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _login.default.extend({
    betterError: Ember.computed('error', function () {
      var errorMessage = this.get('error');

      if (errorMessage && (errorMessage.includes('Bad credentials') || errorMessage.includes("Unable to authenticate user: undefined"))) {
        return "Email or Password is incorrect.";
      } else {
        return this.get('error');
      }
    })
  });
});