define('app-ember/routes/self-guided-story-builder/index', ['exports', 'app-ember/routes/base-authenticated'], function (exports, _baseAuthenticated) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseAuthenticated.default.extend({
    store: Ember.inject.service('store'),
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser'),

    model: function model() {
      return this.modelFor('self-guided-story-builder');
    },


    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('currentStepNumber', 1);
      controller.set('createdStoryNumber', this.get('currentUser.profileable.stories.length'));
    }
  });
});