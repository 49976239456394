define('app-ember/components/videojs-record', ['exports', 'ember-cli-videojs-record/components/videojs-record'], function (exports, _videojsRecord) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _videojsRecord.default;
    }
  });
});