define('app-ember/components/ws-cloud-select-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser'),
    notify: Ember.inject.service('notify'),
    store: Ember.inject.service('store'),
    classNames: ["ws-cloud-select-input"],
    clickedChoiceTopic: null,
    currentTopicLabel: null,
    options: null,
    isNewTopic: null,
    selected: null,

    loseFocusOfInput: function loseFocusOfInput() {
      $(".ws-cloud-select-input input").select().blur();
    },
    removeSelectedTopic: function removeSelectedTopic() {
      this.set('isNewTopic', false);
      this.set('clickedChoiceTopic', null);
      this.set('currentTopicLabel', null);
      return this.set('selected', null);
    },
    addTypedTopic: function addTypedTopic() {
      var _this = this;

      var topics = this.get('options');
      var currentTopicLabel = this.get('currentTopicLabel');

      topics.forEach(function (topic) {
        if (topic.get('label') === currentTopicLabel) {
          _this.set('isNewTopic', false);
          _this.set('clickedChoiceTopic', topic);
          _this.set('currentTopicLabel', topic.get('label'));

          return _this.set('selected', topic);
        }
      });
    },


    actions: {
      onTopicClick: function onTopicClick(topic) {
        // this.loseFocusOfInput();
        this.set('isNewTopic', false);
        this.set('clickedChoiceTopic', topic);
        this.set('currentTopicLabel', topic.get('label'));
        return this.set('selected', topic);
      },
      updateSelectedTopic: function updateSelectedTopic(topic) {
        var _this2 = this;

        var previousTopic = this.get('clickedChoiceTopic');

        if (previousTopic) {
          if (topic.length === previousTopic.get('label.length') - 1) {
            return this.removeSelectedTopic();
          } else if (topic.length > previousTopic.get('label.length')) {
            this.set('currentTopicLabel', topic.split(previousTopic.get('label'))[1]);
            return Ember.RSVP.resolve(this.addTypedTopic()).then(function () {
              _this2.set('clickedChoiceTopic', null);
              return _this2.set('isNewTopic', true);
            });
          }
        } else if (topic.length === 0) {
          this.set('currentTopicLabel', topic);
          return this.removeSelectedTopic();
        } else {
          this.set('currentTopicLabel', topic);
          return Ember.RSVP.resolve(this.addTypedTopic()).then(function () {
            if (Ember.isEmpty(_this2.get('selected.label'))) {
              return _this2.set('isNewTopic', true);
            }
          });
        }
      }
    }
  });
});