define('app-ember/services/model-entities/survey-answers', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service('store'),

    fetchSurveyQuestionUserAnswer: function fetchSurveyQuestionUserAnswer(id) {
      if (id) {
        return this.get('store').find('survey-question-user-answer', id);
      }
    },
    fetchSingleSurveyQuestionUserAnswer: function fetchSingleSurveyQuestionUserAnswer() {
      var queryParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var defaultQueryParams = {};

      return this.get('store').queryRecord('survey-question-user-answer', Object.assign({}, defaultQueryParams, queryParams));
    },
    fetchSurveyQuestionUserAnswers: function fetchSurveyQuestionUserAnswers() {
      var queryParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var defaultQueryParams = {};

      return this.get('store').query('survey-question-user-answer', Object.assign({}, defaultQueryParams, queryParams));
    }
  });
});