define('app-ember/components/bootstrap/tables/-body', ['exports', 'ember-bootstrap-controls/components/bootstrap/tables/-body'], function (exports, _body) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _body.default;
    }
  });
});