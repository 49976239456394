define('app-ember/helpers/to-lower-case', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.toLowerCase = toLowerCase;
  function toLowerCase(params /*, hash*/) {
    return params[0].toLowerCase();
  }

  exports.default = Ember.Helper.helper(toLowerCase);
});