define('app-ember/routes/member/profile/video', ['exports', 'app-ember/routes/base-authenticated'], function (exports, _baseAuthenticated) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseAuthenticated.default.extend({
    model: function model(params) {
      return this.modelFor('member/profile');
    }
  });
});