define('app-ember/components/collapsible-partial-display-panel', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isOpenPanel: null,
    displayModels: null,
    amountDisplayedOnCollapse: null,
    customExpandLabel: null,
    customCollapseLabel: null,

    actions: {
      expandPanel: function expandPanel() {
        this.set('isOpenPanel', !this.get('isOpenPanel'));
      }
    }
  });
});