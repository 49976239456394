define('app-ember/routes/member/profile', ['exports', 'app-ember/routes/base-authenticated'], function (exports, _baseAuthenticated) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseAuthenticated.default.extend({
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser'),
    usersEntity: Ember.inject.service('model-entities/users'),

    model: function model(params) {
      return this.get('usersEntity').fetchUser(params.user_id).then(function (user) {

        return Ember.RSVP.resolve(user.get('profileable')).then(function (profile) {
          return Ember.RSVP.hash({
            user: user,
            profile: profile,
            stories: profile.get('stories')
          }).then(function (hash) {
            if (hash.profile.get('hasCompletedOnboarding') && hash.profile.get('completedProfile')) {
              return hash.user;
            } else if (hash.profile.get('hasCompletedOnboarding') && !hash.profile.get('completedProfile') && hash.stories.filterBy('published').length >= 3) {
              hash.profile.set('completedProfile', true);
              hash.profile.set('profileCompletedAt', new Date());

              return hash.profile.save().then(function () {
                return hash.user.reload();
              });
            } else if (!hash.profile.get('hasCompletedOnboarding')) {
              hash.profile.set('hasCompletedOnboarding', true);
              return hash.profile.save().then(function () {
                return hash.user.reload();
              });
            } else {
              return hash.user;
            }
          });
        });
      });
    },
    afterModel: function afterModel(model) {
      if (this.get('currentUser.id') !== model.get('id')) {
        this.transitionTo('/');
      }
    },


    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set('isEditingProfile', null);
      controller.set('storyLength', model.get('profileable.stories.length'));
    }
  });
});