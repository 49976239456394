define('app-ember/models/job', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    jobPoster: _emberData.default.belongsTo('profile-business', { async: true }),
    organization: _emberData.default.belongsTo('organization', { async: true }),
    client: _emberData.default.belongsTo('client', { async: true }),

    jobCandidates: _emberData.default.hasMany('job-candidate', { async: true }),
    jobSuggestions: _emberData.default.hasMany('job-suggestion', { async: true }),

    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    jobIdentifier: _emberData.default.attr('string'),

    archivedAt: _emberData.default.attr('date'),

    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date')
  });
});