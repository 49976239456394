define('app-ember/controllers/member/profile', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    router: Ember.inject.service('router'),
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser'),
    storyLength: null,

    profileCompletion: Ember.computed('model', 'model.profileable.stories.@each.published', 'model.profileable.stories.[]', function () {
      var stories = this.get('model.profileable.stories');
      var pubStoriesNum = 0;

      if (stories) {
        stories.forEach(function (story) {
          if (story.get('published')) {
            pubStoriesNum += 1;
          }
        });
      }

      if (pubStoriesNum === 1) {
        return 33;
      } else if (pubStoriesNum === 2) {
        return 66;
      } else if (pubStoriesNum >= 3) {
        return 100;
      } else {
        return 0;
      }
    }),

    publicProfileUrl: Ember.computed(function () {
      var user = this.get('model');

      if (user.get('profileable.profileUuid')) {
        var uri = this.get('router').urlFor('public.profile', user.get('profileable.profileUuid'));
        return window.location.href.split('/member/profile/')[0] + ('' + uri);
      } else {
        return "N/A";
      }
    }),

    actions: {
      copySuccess: function copySuccess() {
        this.get('notify').success('Public URL copied to clipboard.');
      },
      copyError: function copyError() {
        this.get('notify').alert('Error with copying your public URL.');
      }
    }
  });
});