define('app-ember/components/ws-selection-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ANSWERS = ['Stayed the same', 'Grown a little', 'Grown a lot', 'Been transformed'];

  exports.default = Ember.Component.extend({
    currentQuestion: null,
    currentAnswer: null,
    answers: ANSWERS,
    answeredLocked: null,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.set('currentAnswer', this.get('currentQuestion.answer'));
    },


    actions: {
      answerQuestion: function answerQuestion(questionModel, userAnswer) {
        this.set('currentAnswer', userAnswer);
        return questionModel.set('answer', userAnswer);
      }
    }
  });
});