define('app-ember/controllers/admin/invite', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    roles: [],
    organizations: [],

    actions: {
      transitionAfter: function transitionAfter() {
        this.send('reloadRoute');
        return this.transitionToRoute('admin.invite');
      },
      transitionAfterCancel: function transitionAfterCancel() {
        return this.transitionToRoute('admin.invitations');
      }
    }
  });
});