define('app-ember/components/ws-card/-header', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['font-weight-bold', 'my-4', 'my-md-4'],
    tagName: 'h3'
  });
});