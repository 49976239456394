define('app-ember/components/s3-file-upload', ['exports', 'app-ember/utils/s3-uploader'], function (exports, _s3Uploader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Ember.FileField.extend({
    session: inject.service('session'),
    newCv: null,

    url: null,
    uuid: null,
    uploadPromise: null,
    uploadPercent: null,

    headers: computed('session.content.token', 'files', function () {
      var token = this.get('session.content.token');

      return { Authorization: 'Token token=' + token };
    }),

    filesDidChange: function () {
      var _this = this;

      var uploadUrl = this.get('url');
      var files = this.get('files');

      var uploader = _s3Uploader.default.create({
        headers: this.get('headers'),
        uuid: this.get('uuid') + '.' + files[0].name.split('.').pop()
      });

      if (uploadUrl) {
        uploader.set('url', uploadUrl);
      }

      uploader.on('progress', function (e) {
        _this.set('uploadPercent', Math.floor(e.percent));
      });

      if (!Ember.isEmpty(files)) {
        this.set('newCv.s3Key', this.get('uuid') + '.' + files[0].name.split('.').pop());
        this.set('newCv.label', files[0].name);

        this.set('uploadPromise', uploader.upload(files[0])); // Uploader will send a sign request then upload to S3
      }
    }.observes('files')
  });
});