define('app-ember/components/progress-bar/-percent-footer', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['progress-footer'],
    percentCompleted: Ember.computed('currentStep', 'totalSteps', function () {
      return Math.trunc(this.get('currentStep') / this.get('totalSteps') * 100);
    })
  });
});