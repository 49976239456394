define('app-ember/components/public-display/story-display-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    stories: null,
    isStoryPanelOpen: null
  });
});