define('app-ember/controllers/organization/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    isOpenInfoPanel: false,
    currentUser: Ember.computed.alias('session.content.currentUser')
  });
});