define('app-ember/routes/video-builder/step-3', ['exports', 'app-ember/routes/base-authenticated'], function (exports, _baseAuthenticated) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseAuthenticated.default.extend({
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.setProperties({
        uploadPercent: 0,
        hasFinishedUpload: false,
        errorDetails: null,
        videoToUpload: null,
        videoName: null,
        recordMode: true
      });
    }
  });
});