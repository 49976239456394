define('app-ember/models/topic', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    profileMember: _emberData.default.belongsTo('profile-member', { async: true }),

    // stories: DS.hasMany('storyV1', { async: true }),
    stories: _emberData.default.hasMany('story-v2', { async: true }),

    label: _emberData.default.attr('string'),
    descriptiveType: _emberData.default.attr('string'),

    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date')
  });
});