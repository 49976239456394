define('app-ember/routes/invite/new/organization/admin', ['exports', 'app-ember/routes/base-authenticated', 'app-ember/models/role', 'app-ember/utils/generate-uuid'], function (exports, _baseAuthenticated, _role, _generateUuid) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseAuthenticated.default.extend({
    session: Ember.inject.service('session'),
    rolesEntity: Ember.inject.service('model-entities/roles'),

    model: function model() {
      var _this = this;

      var currentUser = this.get('session.content.currentUser');
      return this.get('rolesEntity').fetchSingleRole({
        filters: {
          label: _role.ORG_ADMIN
        }
      }).then(function (orgRole) {
        return _this.get('store').createRecord('invitation', {
          requestor: currentUser,
          role: orgRole,
          organization: currentUser.get('profileable.organization'),
          uuid: (0, _generateUuid.default)()
        });
      });
    }
  });
});