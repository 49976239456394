define('app-ember/components/edit-account-form', ['exports', 'ember-authenticate-me/components/edit-account-form'], function (exports, _editAccountForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _editAccountForm.default;
    }
  });
});