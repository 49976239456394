define('app-ember/controllers/organization/show/invitations/new', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    invitation: null,
    success: null,
    userOrganization: null,
    roles: [],

    actions: {
      transitionAfter: function transitionAfter() {
        this.send('reloadRoute');
        return this.transitionToRoute('organization.show.invitations.new');
      },
      transitionAfterCancel: function transitionAfterCancel() {
        return this.transitionToRoute('organization.show.invitations');
      }
    }
  });
});