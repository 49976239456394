define('app-ember/controllers/onboarding/index/create-account', ['exports', 'fetch'], function (exports, _fetch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function sendRequest(model, store, type, requestData) {
    var modelName = model.constructor.modelName;
    var adapter = store.adapterFor(modelName);
    var url = adapter.buildURL(modelName, model.get('id')) + '/' + type;
    // const url = `${adapter.buildURL(modelName, model.get('uuid'))}/${type}`;

    return adapter.ajax(url, 'PUT', { data: requestData });
  }

  exports.default = Ember.Controller.extend({
    sessionLifecycle: Ember.inject.service('session-lifecycle'),
    zipCodeMask: 99999,

    errors: {
      zipCode: [],
      username: [],
      password: [],
      passwordConfirmation: []
    },

    accountCreationErrors: Ember.computed('errors', function () {
      var errors = this.get('errors');
      var result = {
        username: errors.username,
        password: errors.password,
        passwordConfirmation: errors.passwordConfirmation,
        zipCode: errors.zipCode
      };

      if (errors.username) {
        result.username = errors.username.map(function (e) {
          return { message: e };
        });
      } else {
        result.username = [];
      }
      if (errors.password) {
        result.password = errors.password.map(function (e) {
          return { message: e };
        });
      } else {
        result.password = [];
      }
      if (errors.password_confirmation) {
        result.passwordConfirmation = errors.password_confirmation.map(function (e) {
          return { message: e };
        });
      } else {
        result.passwordConfirmation = [];
      }
      if (errors.zip_code) {
        result.zipCode = errors.zip_code.map(function (e) {
          return { message: e };
        });
      } else {
        result.zipCode = [];
      }
      return result;
    }),

    checkForErrors: function checkForErrors() {
      var _this = this;

      var store = this.get('store');
      var adapter = store.adapterFor('User');
      var preValidateUrl = '' + adapter.buildURL('User', 'pre_validate');

      return (0, _fetch.default)(preValidateUrl, {
        headers: { 'Content-Type': 'application/json' },
        method: 'PUT',
        body: JSON.stringify({
          username: this.get('model.username'),
          password: this.get('model.password'),
          password_confirmation: this.get('model.passwordConfirmation')
        })
      }).then(function (response) {
        return Ember.RSVP.hash({ status: response.status, json: response.json() });
      }).then(function (_ref) {
        var status = _ref.status,
            json = _ref.json;

        if (status < 200 || status >= 300) {
          _this.set('errors', json.errors);
        }
        return status;
      });
    },
    acceptInvitationTask: function acceptInvitationTask(invite) {
      var _this2 = this;

      var store = this.get('store');

      var requestData = {
        uuid: invite.get('uuid'),
        invitation: {
          first_name: invite.get('firstName'),
          last_name: invite.get('lastName'),
          email: invite.get('email'),
          username: invite.get('username'),
          uuid: invite.get('uuid'),
          password: invite.get('password'),
          password_confirmation: invite.get('passwordConfirmation'),
          profile_type: invite.get('profileType'),
          zip_code: invite.get('zipCode')
        }
      };

      this.set('_sending', true);
      return sendRequest(invite, store, 'accept', requestData).then(function () {
        _this2.notify.success('Your account was successfully created.');

        return _this2.get('session').open('traditional-authentication', {
          username: invite.get('username'),
          password: invite.get('password')
        }).then(function (sessionContent) {
          var sessionLifecycle = _this2.get('sessionLifecycle');
          sessionLifecycle.userLoggedIn(sessionContent);
          _this2.transitionToRoute('/');
        });
      }).catch(function (error) {
        debugger;
        _this2.notify.alert('An error occured while trying to save your new account.');
        error.errors.map(function (e) {
          return e.detail;
        }).filter(function (e) {
          return e.message;
        }).forEach(function (e) {
          _this2.set('inputErrors.' + e.title, e.message.map(function (m) {
            return { message: m };
          }));
        });
        throw error;
      }).finally(function () {
        _this2.set('_sending', false);
      });
    },


    actions: {
      acceptInvitation: function acceptInvitation(invite) {
        var _this3 = this;

        return this.checkForErrors().then(function (errorCheckStatusCode) {
          if (errorCheckStatusCode >= 200 && errorCheckStatusCode < 300) {
            return _this3.acceptInvitationTask(invite);
          }
        }).catch(function (e) {
          throw e;
        });
      }
    }
  });
});