define('app-ember/models/profile-business', ['exports', 'ember-data', 'app-ember/mixins/models/profileable'], function (exports, _emberData, _profileable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_profileable.default, {
    organizationContactRole: _emberData.default.belongsTo('organization', { async: true, inverse: 'primaryContactProfile' }),

    jobCandidates: _emberData.default.hasMany('job-candidate', { async: true }),
    jobSuggestions: _emberData.default.hasMany('job-suggestion', { async: true }),
    jobPosts: _emberData.default.hasMany('job', { async: true, inverse: 'jobPoster' }),

    role: _emberData.default.belongsTo('role', { async: true }),
    organization: _emberData.default.belongsTo('organization', { async: true }),

    roleLabel: _emberData.default.attr('string'),

    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),

    isRecruiter: Ember.computed('roleLabel', function () {
      var roleName = this.get('roleLabel');

      if (roleName) {
        return roleName === "Recruiter";
      }
    })
  });
});