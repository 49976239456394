define('app-ember/components/screen-name-generator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service('store'),

    actions: {
      generateNewUsername: function generateNewUsername() {
        var _this = this;

        var store = this.get('store');
        var adapter = store.adapterFor('User');
        var autoGenUrl = '' + adapter.buildURL('User', 'generate_username');
        return adapter.ajax(autoGenUrl, 'GET').then(function (response) {
          _this.set('screenName', Ember.get(response, 'username'));
          _this.set('errors', null);
        });
      }
    }
  });
});