define('app-ember/routes/onboarding/account-setup', ['exports', 'ember-authenticate-me/mixins/routes/unauthenticate-user'], function (exports, _unauthenticateUser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticateUser.default, {
    model: function model(params) {
      return this.store.findRecord('invitation', params.invitation_id);
    },


    actions: {
      error: function error(_error, transition) {
        if (_error.errors[0] && _error.errors[0].status === '403') {
          this.notify.alert('That invite link has expired or already been claimed.');
          this.replaceWith('index');
        } else {
          return true;
        }
      }
    }
  });
});