define('app-ember/components/bootstrap/tables/-pagination-control', ['exports', 'ember-bootstrap-controls/components/bootstrap/tables/-pagination-control'], function (exports, _paginationControl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _paginationControl.default;
    }
  });
});