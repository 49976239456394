define('app-ember/routes/member/profile/index', ['exports', 'app-ember/routes/base-authenticated'], function (exports, _baseAuthenticated) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseAuthenticated.default.extend({
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser'),

    model: function model() {
      return this.modelFor('member/profile');
    },


    afterModel: function afterModel(model) {
      var _this = this;

      var filters = {
        profile_id: this.get('currentUser.profileable.id')
      };

      return Ember.RSVP.hash({
        profile: model.get('profileable'),
        stories: this.get('store').query('story-v2', { filters: filters }),
        v1Stories: this.get('store').query('story-v1', { filters: filters })
      }).then(function (hash) {
        _this.set('profile', hash.profile);
        _this.set('stories', hash.stories);
        _this.set('favoritedStories', hash.stories.filter(function (s) {
          return s.get('favorite');
        }));
        return _this.set('v1Stories', hash.v1Stories);
      });
    },

    setupController: function setupController(controller /*, model*/) {
      this._super.apply(this, arguments);

      controller.setProperties({
        favoritedStoriesCount: this.get('favoritedStories.length'),
        profile: this.get('profile'),
        stories: this.get('stories'),
        v1Stories: this.get('v1Stories')
      });
    }
  });
});