define('app-ember/controllers/onboarding/account-setup', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function sendRequest(model, store, type, requestData) {
    var modelName = model.constructor.modelName;
    var adapter = store.adapterFor(modelName);
    var url = adapter.buildURL(modelName, model.get('id')) + '/' + type;
    // const url = `${adapter.buildURL(modelName, model.get('uuid'))}/${type}`;

    return adapter.ajax(url, 'PUT', { data: requestData });
  }

  exports.default = Ember.Controller.extend({
    inviteErrors: {
      password: null,
      passwordConfirmation: null,
      zipCode: null
    },

    isSaving: false,

    hasCorrectInformation: Ember.computed('model.password', 'model.passwordConfirmation', function () {
      var password = this.get('model.password');
      var confirmationPw = this.get('model.passwordConfirmation');

      if (password && confirmationPw) {
        if (confirmationPw === password) {
          return true;
        } else {
          return false;
        }
      }
    }),

    acceptInvitationTask: function acceptInvitationTask(invite) {
      var _this = this;

      var store = this.get('store');

      var requestData = {
        uuid: invite.get('uuid'),
        invitation: {
          first_name: invite.get('firstName'),
          last_name: invite.get('lastName'),
          email: invite.get('email'),
          uuid: invite.get('uuid'),
          password: invite.get('password'),
          password_confirmation: invite.get('passwordConfirmation'),
          zip_code: invite.get('zipCode'),
          profile_type: invite.get('profileType')
        }
      };

      this.set('isSaving', true);
      return sendRequest(invite, store, 'accept', requestData).then(function () {
        _this.notify.success('Your account was successfully created.');
        _this.transitionToRoute('login');
      }).catch(function (E) {
        _this.notify.alert('An error occured while trying to save your new account.');

        E.errors.forEach(function (error) {
          if (error.detail.message) {
            _this.set('inviteErrors.' + error.detail.title, []);

            error.detail.message.forEach(function (err) {
              _this.get('inviteErrors.' + error.detail.title).pushObject({ message: err });
            });
          }
        });

        return E;
      }).finally(function () {
        _this.set('isSaving', false);
      });
    },


    actions: {
      acceptInvitation: function acceptInvitation(invite) {
        return this.acceptInvitationTask(invite);
      }
    }
  });
});