define('app-ember/services/model-entities/story-attributes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service('store'),

    fetchStoryAttribute: function fetchStoryAttribute(id) {
      if (id) {
        return this.get('store').find('story-attribute', id);
      }
    },
    fetchStoryAttributes: function fetchStoryAttributes() {
      var queryParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var defaultQueryParams = {};

      return this.get('store').query('story-attribute', Object.assign({}, defaultQueryParams, queryParams));
    }
  });
});