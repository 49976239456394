define('app-ember/controllers/video-builder/step-2', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser'),
    selectedStory: null,

    actions: {
      selectStory: function selectStory(story) {
        this.set('selectedStory', story);
      },
      onNext: function onNext() {
        this.transitionToRoute('video-builder.step-3');
      },
      onBack: function onBack() {
        this.transitionToRoute('video-builder.index');
      }
    }
  });
});