define('app-ember/components/ws-story-card', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    editingStory: null,
    favoritedStoriesCount: 0,
    addingNewStory: null,
    createAttributeStory: null,
    createTopicStory: null,

    actions: {
      onHidden: function onHidden() {
        return this.set('addingNewStory', false);
      },
      addNewStory: function addNewStory() {
        return this.set('addingNewStory', true);
      },
      cancelAddStory: function cancelAddStory() {
        return this.set('addingNewStory', false);
      }
    }
  });
});