define('app-ember/serializers/story-v2', ['exports', 'ember-data', 'app-ember/serializers/application'], function (exports, _emberData, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      storyAttributes: { serialize: false, deserialize: 'ids' }
    },

    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      var key = relationship.key;

      json[(0, _application.getHasManyPayloadKey)(key)] = snapshot.hasMany(key, { ids: true });
    }
  });
});