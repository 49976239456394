define("app-ember/transitions/flip-x", ["exports", "app-ember/transitions/flip"], function (exports, _flip) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (opts) {
    return _flip.default.call(this, 'x', opts);
  };
});