define('app-ember/components/s3-file-uploader', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    uploadPercent: null,
    finishedUpload: null
  });
});