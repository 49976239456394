define('app-ember/controllers/organization/show/search', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    loading: false,

    searchText: '',
    searchByName: true,
    searchByUsername: false,
    searchByEmail: false,
    organization: null,

    pageNumber: 1,
    pageSize: 20,
    sortCriteria: "id",
    sortReverse: false,

    attributeFilter: [],
    selectedAttributes: [],
    topicFilter: [],
    selectedTopics: [],
    workSkillFilter: [],
    selectedWorkSkills: [],

    uiSelectedAttributes: Ember.computed('model.storyAttributes', 'attributeFilter', function () {
      var _this = this;

      var attributes = [];

      this.get('model.storyAttributes').forEach(function (attribute) {
        if (_this.get('attributeFilter').includes(attribute.get('id'))) {
          attributes.push(attribute);
        }
      });

      return attributes;
    }),

    uiSelectedTopics: Ember.computed('model.topics', 'topicFilter', function () {
      var _this2 = this;

      var topics = [];

      this.get('model.topics').forEach(function (topic) {
        if (_this2.get('topicFilter').includes(topic.get('id'))) {
          topics.push(topic);
        }
      });

      return topics;
    }),

    uiSelectedWorkSkills: Ember.computed('model.workSkills', 'workSkillFilter', function () {
      var _this3 = this;

      var workSkills = [];

      this.get('model.workSkills').forEach(function (workSkill) {
        if (_this3.get('workSkillFilter').includes(workSkill.get('id'))) {
          workSkills.push(workSkill);
        }
      });

      return workSkills;
    }),

    actions: {
      changeSearchText: function changeSearchText(value) {
        this.set('searchText', value);
        this.set('pageNumber', 1);
      },
      selectSearchByName: function selectSearchByName(value) {
        this.set('searchByName', value);
        this.set('searchByUsername', false);
        this.set('searchByEmail', false);
        this.set('pageNumber', 1);
      },
      selectSearchByUsername: function selectSearchByUsername(value) {
        this.set('searchByUsername', value);
        this.set('searchByName', false);
        this.set('searchByEmail', false);
        this.set('pageNumber', 1);
      },
      selectSearchByEmail: function selectSearchByEmail(value) {
        this.set('searchByEmail', value);
        this.set('searchByUsername', false);
        this.set('searchByName', false);
        this.set('pageNumber', 1);
      },
      selectAttribute: function selectAttribute(options) {
        this.set('attributeFilter', options.map(function (option) {
          return option.id;
        }));
        this.set('selectedAttributes', options);
        this.set('pageNumber', 1);
      },
      selectTopic: function selectTopic(options) {
        this.set('topicFilter', options.map(function (option) {
          return option.id;
        }));
        this.set('selectedTopics', options);
        this.set('pageNumber', 1);
      },
      selectWorkSkill: function selectWorkSkill(options) {
        this.set('workSkillFilter', options.map(function (option) {
          return option.id;
        }));
        this.set('selectedWorkSkills', options);
        this.set('pageNumber', 1);
      },
      resetSearch: function resetSearch() {
        this.setProperties({
          searchByName: true,
          searchByUsername: false,
          searchByEmail: false,
          searchText: '',
          attributeFilter: [],
          selectedAttributes: [],
          topicFilter: [],
          selectedTopics: [],
          workSkillFilter: [],
          selectedWorkSkills: [],
          pageNumber: 1
        });
      }
    }
  });
});