define('app-ember/components/freestyle/bootstrap/tables/body/row/actions/-delete', ['exports', 'ember-bootstrap-controls/components/freestyle/bootstrap/tables/body/row/actions/-delete'], function (exports, _delete) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _delete.default;
    }
  });
});