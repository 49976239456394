define('app-ember/components/freestyle/bootstrap-form', ['exports', 'ember-bootstrap-controls/components/freestyle/bootstrap-form'], function (exports, _bootstrapForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _bootstrapForm.default;
    }
  });
});