define('app-ember/controllers/organization-member/profile/password-change', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    actions: {
      transitionAction: function transitionAction(user) {
        return this.transitionToRoute('organization-member.profile', user);
      }
    }
  });
});