define("app-ember/components/freestyle/ws-button-dropdown", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    actions: {
      fakeAction: function fakeAction() {
        console.log("I'm an action!"); // eslint-disable-line no-console
      }
    }
  });
});