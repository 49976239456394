define('app-ember/routes/organization-member/profile', ['exports', 'app-ember/routes/base-authenticated'], function (exports, _baseAuthenticated) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseAuthenticated.default.extend({
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser'),
    usersEntity: Ember.inject.service('model-entities/users'),

    model: function model(params) {
      return this.get('usersEntity').fetchUser(params.user_id);
    },


    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('emailChanged', false);
    }
  });
});