define('app-ember/controllers/admin/members', ['exports', 'ember-light-table'], function (exports, _emberLightTable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['pageNumber', 'pageSize', "sortCriteria", "sortReverse", "filterOrganizationId", "recruiterFilterId"],
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser'),
    usersEntity: Ember.inject.service('model-entities/users'),
    media: Ember.inject.service(),

    pageNumber: 1,
    pageSize: 25,
    totalRecords: null,
    currentIterationCount: null,

    sortCriteria: "id",
    sortReverse: false,

    organizations: null,
    // model: null,
    // rows: [],
    filterOrganizationLabel: null,
    filterOrganization: null,
    recruiterFilter: null,
    recruiterFilterId: null,

    cellWidth: Ember.computed('media', function () {
      var media = this.get('media');

      if (media.get('isMobile')) {
        return '9rem';
      } else {
        return 'null';
      }
    }),

    filterOrganizationOptions: Ember.computed('organizations', function () {
      var organizations = this.get('organizations').map(function (org) {
        return org.get('label');
      });
      organizations.unshift("Unaffiliated");

      return organizations;
    }),

    recruiterFilterOptions: Ember.computed('filterOrganization', function () {
      var filterOrg = this.get('filterOrganization');
      if (filterOrg !== "Unaffiliated") {
        var promise = this.get('usersEntity').fetchUsers({
          filters: {
            profileable_type: 'ProfileBusiness',
            organization_user: filterOrg,
            only_recruiters: true
          }
        });

        return Ember.RSVP.resolve(promise).then(function (recruiters) {
          return recruiters;
        });
      } else {
        return null;
      }
    }),

    columns: Ember.computed(function () {
      return [{
        label: 'Screen Name',
        valuePath: 'username',
        cellComponent: 'table-cell/public-profile-link',
        width: this.get('cellWidth')
      }, {
        label: 'Name',
        valuePath: 'fullName',
        width: this.get('cellWidth')
      }, {
        label: 'Email',
        valuePath: 'email',
        width: this.get('cellWidth')
      }, {
        label: 'Resume',
        sortable: false,
        cellComponent: 'table-cell/downloadable-resume',
        width: this.get('cellWidth')
      }, {
        label: 'Joined',
        valuePath: 'createdAt',
        cellComponent: 'table-cell/formatted-date',
        width: this.get('cellWidth')
      }, {
        label: 'Status',
        valuePath: 'status',
        width: this.get('cellWidth')
      }, {
        label: 'Profile',
        valuePath: 'profileable.completedProfile',
        sortable: false,
        cellComponent: 'table-cell/completed-profile',
        width: this.get('cellWidth')
      }, {
        label: '',
        valuePath: 'status',
        sortable: false,
        cellComponent: 'table-cell/activate-block-toggle',
        width: this.get('cellWidth')
      }];
    }),

    table: Ember.computed('model', 'media', function () {
      return new _emberLightTable.default(this.get('columns'), this.get('model').toArray(), { enableSync: true });
    }),

    actions: {
      onColumnSort: function onColumnSort(column) {
        this.set('sortCriteria', column.get('valuePath'));
        this.set('sortReverse', column.ascending ? false : true);
      },
      updateOrganizationFilter: function updateOrganizationFilter(selected) {
        var _this = this;

        if (selected !== "Unaffiliated") {
          this.get('organizations').forEach(function (org) {
            if (org.get('label') === selected) {
              _this.set('filterOrganizationLabel', org.get('label'));
              _this.set('filterOrganization', org.get('id'));
              _this.getRecruiterFilters();
            }
          });
        } else {
          this.set('filterOrganizationLabel', selected);
          this.set('filterOrganization', selected);
        }

        return this.set('pageNumber', 1);
      },
      updateRecruiterFilter: function updateRecruiterFilter(selected) {
        this.set('pageNumber', 1);

        this.set('recruiterFilter', selected);
        return this.set('recruiterFilterId', selected.get('id'));
      },
      resetFilters: function resetFilters() {
        this.set('pageNumber', 1);
        this.set('filterOrganizationLabel', null);
        this.set('filterOrganization', null);
        this.set('recruiterFilter', null);
        this.set('recruiterFilterId', null);
      }
    }
  });
});