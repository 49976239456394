define('app-ember/components/ws-card/-action', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'p',
    classNames: ['ws-action-block', 'p-3', 'mt-1']
  });
});