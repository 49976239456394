define('app-ember/routes/admin/invite', ['exports', 'app-ember/routes/base-authenticated', 'app-ember/models/role'], function (exports, _baseAuthenticated, _role) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseAuthenticated.default.extend({
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser'),
    rolesEntity: Ember.inject.service('model-entities/roles'),
    organizationsEntity: Ember.inject.service('model-entities/organizations'),

    afterModel: function afterModel(model) {
      var _this = this;

      var labels = [_role.SITE_ADMIN, _role.ORG_ADMIN]; // If you want to enable invitations on orgs behalf you would add RECRUITER and MEMEBER to this.
      return this.get('organizationsEntity').fetchOrganizations({}).then(function (organizations) {
        _this.set('organizations', organizations);
        return _this.get('rolesEntity').fetchRoles({
          filters: {
            labels: labels
          }
        });
      }).then(function (availableRoles) {
        _this.set('roles', availableRoles);
        return model;
      });
    },


    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.setProperties({
        roles: this.get('roles'),
        organizations: this.get('organizations')
      });
    },

    actions: {
      reloadRoute: function reloadRoute() {
        this.refresh();
      }
    }
  });
});