define('app-ember/routes/quick-note/tip4', ['exports', 'app-ember/routes/base-authenticated'], function (exports, _baseAuthenticated) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseAuthenticated.default.extend({
    model: function model(params) {
      return this.set('transitioningRoute', params.transitionRoute);
    },


    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('transitioningRoute', this.get('transitioningRoute'));
    }
  });
});