define('app-ember/templates/components/freestyle-collection', ['exports', 'ember-freestyle/templates/components/freestyle-collection'], function (exports, _freestyleCollection) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _freestyleCollection.default;
    }
  });
});