define("app-ember/snippets", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "advanced-bar--usage.hbs": "          {{#progress-bar/normal\n            totalSteps=10\n            currentStep=5\n            as |progress|\n          }}\n            {{progress.header header='Some progress'}}\n            {{progress.bar barClass='bg-info'}}\n            {{progress.percentFooter}}\n          {{/progress-bar/normal}}",
    "advanced-card--usage.hbs": "          {{#ws-card/normal as |wsCard|}}\n            {{#wsCard.header}}Advanaced Header{{/wsCard.header}}\n            {{#wsCard.body as |body|}}\n              {{#body.action}}\n                <p><strong>Example Story</strong></p>\n                <span class=\"sample\"><span class=\"topic\">Playing college soccer</span> contributed to my growth in <span class=\"attribute\">Self-Awareness</span>, <span class=\"attribute\">Humility</span>, and <span class=\"attribute\">Teamwork</span> by giving my all to our collective accomplishment (Division II National Finals!) despite not getting much playing time.</span>\n              {{/body.action}}\n              {{#body.info}}\n                Unicorn skateboard cardigan williamsburg mlkshk hell of. Artisan kickstarter roof party.\n              {{/body.info}}\n            {{/wsCard.body}}\n            {{#wsCard.footer\n              hint=\"This is a hint. Isn't it awesome!\"\n              primaryAction=(action 'fakeAction')\n              secondaryAction=(action 'fakeAction')\n              as |footer|\n            }}\n              {{footer.hint}}\n              {{#footer.secondaryButton}}Chocolate{{/footer.secondaryButton}}\n              {{#footer.primaryButton}}Bananas{{/footer.primaryButton}}\n            {{/wsCard.footer}}\n          {{/ws-card/normal}}",
    "basic-bar--usage.hbs": "          {{input\n            type=\"number\"\n            min=0\n            max=10\n            value=currentStep\n          }}\n          {{progress-bar/normal\n            totalSteps=10\n            currentStep=currentStep\n            header='Basic Progress Bar'\n          }}",
    "basic-card--usage.hbs": "          {{#ws-card/normal as |wsCard|}}\n            {{#wsCard.header}}Header{{/wsCard.header}}\n            {{#wsCard.body as |body|}}\n              {{#body.info}}\n                Unicorn skateboard cardigan williamsburg mlkshk hell of. Artisan kickstarter roof party try-hard snackwave yuccie ennui bicycle rights health goth retro prism. 3 wolf moon lomo deep v, succulents microdosing blue bottle vape bitters you probably haven't heard of them. Small batch street art chicharrones mlkshk pabst irony hoodie meditation put a bird on it mustache crucifix. Chartreuse tousled bicycle rights vice art party aesthetic woke ennui salvia. Blog YOLO thundercats tote bag.\n              {{/body.info}}\n            {{/wsCard.body}}\n            {{wsCard.footer\n              hint=\"This is a hint. Isn't it awesome!\"\n              primaryAction=(action 'fakeAction')\n              secondaryAction=(action 'fakeAction')\n            }}\n          {{/ws-card/normal}}",
    "button-dropdown--usage.hbs": "          {{#ws-button-dropdown\n            label=label\n            buttonType=\"btn-primary\"\n          }}\n            <a class=\"dropdown-item\" href=\"#\">Action</a>\n            <a class=\"dropdown-item\" href=\"#\">Another action</a>\n            <a class=\"dropdown-item\" href=\"#\">Something else here</a>\n            <div class=\"dropdown-divider\"></div>\n            <a class=\"dropdown-item\" href=\"#\">Separated link</a>\n          {{/ws-button-dropdown}}",
    "centered-card--usage.hbs": "          {{#ws-card/centered as |wsCard|}}\n            {{#wsCard.header}}Header{{/wsCard.header}}\n            {{#wsCard.body as |body|}}\n              {{#body.info}}\n                Unicorn skateboard cardigan williamsburg mlkshk hell of. Artisan kickstarter roof party try-hard snackwave yuccie ennui bicycle rights health goth retro prism. 3 wolf moon lomo deep v, succulents microdosing blue bottle vape bitters you probably haven't heard of them. Small batch street art chicharrones mlkshk pabst irony hoodie meditation put a bird on it mustache crucifix. Chartreuse tousled bicycle rights vice art party aesthetic woke ennui salvia. Blog YOLO thundercats tote bag.\n              {{/body.info}}\n            {{/wsCard.body}}\n            {{wsCard.footer\n              primaryAction=(action 'fakeAction')\n            }}\n          {{/ws-card/centered}}",
    "flexbox-table--usage.hbs": "      {{#flexbox-table as |flexboxTable|}}\n      {{/flexbox-table}}",
    "flippy-card--usage.hbs": "          {{#ws-card/flippy\n            primaryAction=(action 'fakeAction')\n            secondaryAction=(action 'fakeAction')\n            as |wsCard|\n          }}\n            {{#liquid-if wsCard.frontSide class=\"flippy-card\"}}\n              {{#wsCard.front as |frontCard|}}\n                {{frontCard.header header=\"Front\"}}\n                {{#frontCard.body}}\n                  <p>Front Side of the Card</p>\n                {{/frontCard.body}}\n                {{frontCard.footer}}\n              {{/wsCard.front}}\n            {{else}}\n              {{#wsCard.back as |backCard|}}\n                {{backCard.header header=\"Back\"}}\n                {{#backCard.body}}\n                  <p>Back Side of the Card</p>\n                {{/backCard.body}}\n                {{backCard.footer}}\n              {{/wsCard.back}}\n            {{/liquid-if}}\n          {{/ws-card/flippy}}",
    "fp:notes.js": "### A few notes regarding freestyle-palette\n\n- Accepts a colorPalette POJO like the one found in the freestyle.js blueprint controller\n- Looks very nice\n\nAnd another thing...\n\n###### Markdown note demonstrating prettified code\n\n```\nimport Ember from 'ember';\n\nexport default Ember.Component.extend({\n  // ...\n  colorPalette: {\n    'primary': {\n      'name': 'cyan',\n      'base': '#00bcd4'\n    },\n    'accent': {\n      'name': 'amber',\n      'base': '#ffc107'\n    }\n  }\n  // ...\n});\n```",
    "small-card--usage.hbs": "          {{#ws-card/small as |wsCard|}}\n            {{#wsCard.header}}Header{{/wsCard.header}}\n            {{#wsCard.body as |body|}}\n              {{#body.info}}\n                Unicorn skateboard cardigan williamsburg mlkshk hell of. Artisan kickstarter roof party try-hard snackwave yuccie ennui bicycle rights health goth retro prism. 3 wolf moon lomo deep v, succulents microdosing blue bottle vape bitters you probably haven't heard of them. Small batch street art chicharrones mlkshk pabst irony hoodie meditation put a bird on it mustache crucifix. Chartreuse tousled bicycle rights vice art party aesthetic woke ennui salvia. Blog YOLO thundercats tote bag.\n              {{/body.info}}\n            {{/wsCard.body}}\n            {{wsCard.footer\n              hint=\"This is a hint. Isn't it awesome!\"\n              primaryAction=(action 'fakeAction')\n              secondaryAction=(action 'fakeAction')\n            }}\n          {{/ws-card/small}}",
    "story-primer-card--usage.hbs": "          {{ws-eval-card\n            questionAnswerModels=questionAnswerModels\n            isSaving=isSaving\n            completeEvaluation=(action 'completeEvaluation')\n          }}",
    "strength-picker--usage.hbs": "              {{ws-strength-picker\n                mobileSelect=mobileSelect\n                value=value\n                min=1\n                max=10\n                lowExample='This is a low example. See how low it is.'\n                highExample='This is a high example. See how high it is.'\n              }}"
  };
});