define('app-ember/router', ['exports', 'app-ember/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('index', { path: '/' });
    this.route('freestyle');
    this.route('login');
    this.route('logout');
    this.route('sign-up');
    this.route('forgot-password');

    this.route('reset-password', {
      path: '/reset-password/:token'
    });

    this.route('edit-account', {
      path: '/edit-account/:user_id'
    });

    this.route('self-eval', { path: 'story-primer' }, function () {
      this.route('index');
      this.route('results');
      this.route('achievement');
    });

    this.route('welcome', function () {
      this.route('edu-1');
      this.route('edu-2');
      this.route('edu-3');
      this.route('self-eval-intro', { path: 'story-primer-intro' });
      this.route('story-primer-intro-2');
      this.route('story-primer-intro-continued');
    });

    this.route('video-builder', function () {
      this.route('index', { path: 'step-1' });
      this.route('step-2');
      this.route('step-3');
      this.route('step-4');
    });

    this.route('story-creation', { path: 'story-builder' }, function () {
      this.route('story-intro');
      this.route('sample-story');
      this.route('review');
      this.route('more-attributes');
      this.route('story-cue1');
      this.route('story-cue2');
      this.route('story-cue3');
      this.route('achievement');
    });

    this.route('quick-note', function () {
      this.route('tip1', { path: 'tip1/:transitionRoute' });
      this.route('tip2', { path: 'tip2/:transitionRoute' });
      this.route('tip3', { path: 'tip3/:transitionRoute' });
      this.route('tip4', { path: 'tip4/:transitionRoute' });
      this.route('tip5', { path: 'tip5/:transitionRoute' });
      this.route('tip6', { path: 'tip6/:transitionRoute' });
    });

    this.route('member', function () {
      this.route('profile', { path: 'profile/:user_id' }, function () {
        this.route('index', { path: 'my-stories' });
        this.route('password-change');
        this.route('employment-information');
        this.route('education-and-skills');
        this.route('account-information');
        this.route('primer-information');
        this.route('video');
      });
    });

    this.route('organization', function () {
      this.route('show', { path: 'show/:organization_id' }, function () {
        this.route('recruiters');
        this.route('members', { path: 'candidates' });
        this.route('jobs');
        this.route('invitations', function () {
          this.route('index');
          this.route('new');
        });
        this.route('search');
      });
    });

    this.route('admin', function () {
      this.route('invite');
      this.route('organizations');
      this.route('invitations', function () {});
      this.route('members');
      this.route('recruiters');

      this.route('strength-assessment', function () {
        this.route('edit', { path: ':survey_question_id/edit' });
      });
    });

    this.route('invite', function () {
      this.route('create-organization');
    });

    this.route('onboarding', function () {
      // this.route('index', { path: ':uuid/index'}, function() {});
      this.route('index', { path: ':invitation_id/index' }, function () {
        this.route('info-1');
        this.route('info-2');
        this.route('create-account');
        this.route('create-account-1');
        this.route('info', { path: '/' });
      });

      this.route('account-setup', { path: ':invitation_id/account-setup' }, function () {});
    });

    this.route('deactivated-account', { path: 'deactivated-account/:user_id' });

    this.route('public', function () {
      this.route('profile', { path: 'profile/:profileUuid' }, function () {
        this.route('video');
      });
    });

    this.route('organization-member', function () {
      this.route('profile', { path: 'profile/:user_id' }, function () {
        this.route('index', { path: 'account-information' });
        this.route('edit');
        this.route('password-change');
      });
    });

    this.route('self-guided-story-builder', function () {
      this.route('index');
      this.route('review', { path: 'review/:story_id' });
      this.route('sample-story');
    });

    this.route('self-signup', function () {
      this.route('index', function () {
        this.route('info');
        this.route('info-1');
        this.route('info-2');
        this.route('create-account');
        this.route('create-account-1');
      });
    });

    this.route('signup', { path: 'signup/:organization_id' }, function () {
      this.route('index', function () {
        this.route('info');
        this.route('info-1');
        this.route('info-2');
        this.route('create-account');
        this.route('create-account-1');
      });
    });

    this.route('expired-invitation', { path: ':invitation_id/expired-invitation' });

    this.route('member-survey', { path: 'member-survey/:profileUuid' }, function () {
      this.route('response-yes');
      this.route('response-no');
      this.route('response-other');
    });
  });

  exports.default = Router;
});