define('app-ember/controllers/self-guided-story-builder/review', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    selfGuidedStoryBuilder: Ember.inject.controller(),
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser'),
    addingNewStory: false,
    isEditing: false,

    storyNumber: Ember.computed(function () {
      var createdStoryNumber = this.get('selfGuidedStoryBuilder.createdStoryNumber');
      var lastNum = createdStoryNumber.toString().slice(-1);

      if (lastNum === "1" && createdStoryNumber !== 11) {
        return createdStoryNumber + 'st';
      } else if (lastNum === "2" && createdStoryNumber !== 12) {
        return createdStoryNumber + 'nd';
      } else if (lastNum === "3" && createdStoryNumber !== 13) {
        return createdStoryNumber + 'rd';
      } else {
        return createdStoryNumber + 'th';
      }
    }),

    actions: {
      addNewStory: function addNewStory() {
        this.set('addingNewStory', true);
      },
      cancelAddStory: function cancelAddStory() {
        this.set('addingNewStory', false);
      },
      createTopicStory: function createTopicStory() {
        this.get('selfGuidedStoryBuilder').send('navigateToQuickNote', "to-topic");
      },
      createAttributeStory: function createAttributeStory() {
        this.get('selfGuidedStoryBuilder').send('navigateToQuickNote', "to-attribute");
      },
      editStory: function editStory() {
        this.set('isEditing', true);
      }
    }
  });
});