define('app-ember/components/public-display/work-skills-panel', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    actions: {
      noSelect: function noSelect(option) {
        return option;
      }
    }
  });
});