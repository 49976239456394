define('app-ember/components/bootstrap-date-picker', ['exports', 'ember-bootstrap-controls/components/bootstrap-date-picker'], function (exports, _bootstrapDatePicker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _bootstrapDatePicker.default;
    }
  });
});