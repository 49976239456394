define('app-ember/components/story-creation/story-cue2', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    router: Ember.inject.service('router'),
    story: null,
    maxNumberOfCharacters: 225,
    storyNumber: null,
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser'),

    numberOfCharactersLeft: Ember.computed('story.cue2', function () {
      var charactersUsed = this.get('story.cue2.length');
      if (charactersUsed) {
        return this.get('maxNumberOfCharacters') - charactersUsed;
      } else {
        return this.get('maxNumberOfCharacters');
      }
    }),
    actions: {
      cancelAction: function cancelAction(story) {
        story.rollbackAttributes();
        return this.get('router').transitionTo('member.profile', this.get('currentUser.id'));
      }
    }
  });
});