define('app-ember/routes/member/profile/account-information', ['exports', 'app-ember/routes/base-authenticated'], function (exports, _baseAuthenticated) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseAuthenticated.default.extend({
    model: function model(params) {
      return this.modelFor('member/profile');
    },
    afterModel: function afterModel(model) {
      var _this = this;

      return Ember.RSVP.resolve(model.get('profileable')).then(function (profile) {
        return Ember.RSVP.hash({
          profile: profile,
          isEditingProfile: model.get('needToChangeUsername')
        }).then(function (hash) {
          _this.set('userProfile', hash.profile);
          _this.set('isEditingProfile', hash.isEditingProfile);
          return model;
        });
      });
    },


    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('userProfile', this.get('userProfile'));
      controller.set('emailChanged', false);
      controller.set('isEditingProfile', this.get('isEditingProfile'));
    }
  });
});