define('app-ember/components/story-creation/more-attributes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    router: Ember.inject.service('router'),
    attributesEntity: Ember.inject.service('model-entities/story-attributes'),
    selectedAttributes: null,
    storyNumber: null,
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser'),

    attributes: Ember.computed(function () {
      return this.get('attributesEntity').fetchStoryAttributes();
    }),

    actions: {
      cancelAction: function cancelAction(story) {
        this.set('attributes', null);
        story.rollbackAttributes();

        return this.get('router').transitionTo('member.profile', this.get('currentUser.id'));
      }
    }
  });
});