define('app-ember/components/invite-form-row', ['exports', 'app-ember/models/role'], function (exports, _role) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser'),
    tagName: '',
    invitation: null,
    cancel: null,
    success: null,
    roles: [],
    organizations: [],
    requestors: [],
    isSaving: false,
    jobs: null,
    selectedJobs: [],

    userOrganization: null,

    multipleRoles: Ember.computed('roles', function () {
      return this.get('roles.length') > 1;
    }),

    needsOrganization: Ember.computed('invitation.role.label', function () {
      var roleLabel = this.get('invitation.role.label');
      var noOrganization = Ember.isBlank(this.get('invitation.organization.id'));
      return noOrganization && (roleLabel === _role.RECRUITER || roleLabel === _role.MEMBER);
    }),

    actions: {
      roleSelected: function roleSelected(role) {
        if (!Ember.isBlank(role) && (role.get('label') === _role.SITE_ADMIN || role.get('label') === _role.ORG_ADMIN)) {
          this.set('invitation.organization', null);
          this.set('invitation.requestor', this.get('currentUser'));
        }
        this.set('invitation.role', role);
      },
      organizaitonSelected: function organizaitonSelected(organization) {
        var _this = this;

        this.get('invitation').set('organization', organization);
        if (!Ember.isBlank(organization)) {
          return this.get('userEntity').fetchUsers({
            filters: {
              organization_id: organization.get('id'),
              role_types: [_role.ORG_ADMIN, _role.RECRUITER]
            }
          }).then(function (requestors) {
            _this.set('requestors', requestors);
            _this.get('invitation').set('requestor', requestors.get('firstObject'));
          });
        }
      }
    }
  });
});