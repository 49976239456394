define('app-ember/services/model-entities/survey-questions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service('store'),

    fetchSurveyQuestions: function fetchSurveyQuestions(queryParams) {
      return this.get('store').query('survey-question', queryParams);
    }
  });
});