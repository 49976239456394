define('app-ember/serializers/token-authenticate-me/user', ['exports', 'ember-data', 'ember-authenticate-me/serializers/user', 'app-ember/serializers/application'], function (exports, _emberData, _user, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = _user.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      profileable_id: { serialize: true, deserialize: true },
      profileable_type: { serialize: true, deserialize: true }
    },

    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      var key = relationship.key;

      json[(0, _application.getHasManyPayloadKey)(key)] = snapshot.hasMany(key, { ids: true });
    },


    serialize: function serialize(recordSnapshot /*, options*/) {
      var json = this._super.apply(this, arguments);
      var password = get(recordSnapshot.record, 'password');
      var passwordConfirmation = get(recordSnapshot.record, 'passwordConfirmation');
      var currentPassword = get(recordSnapshot.record, 'currentPassword');
      var emailConfirmation = get(recordSnapshot.record, 'emailConfirmation');

      var profileable_id = get(recordSnapshot.record, 'profileable_id');
      var profileable_type = get(recordSnapshot.record, 'profileable_type');

      json.profileable_id = profileable_id;
      json.profileable_type = profileable_type;

      json.password = password;
      json.password_confirmation = passwordConfirmation;
      json.current_password = currentPassword;
      json.email_confirmation = emailConfirmation;

      return json;
    }
  });
});