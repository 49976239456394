define('app-ember/controllers/admin/strength-assessment/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    notify: Ember.inject.service('notify'),
    actions: {
      save: function save(model) {
        var _this = this;

        return model.save().then(function (savedModel) {
          _this.get('notify').success('Save Successful');
          return _this.transitionToRoute('admin.strength-assessment.index');
        }).catch(function (e) {
          _this.get('notify').alert('Unable to Save.');
        });
      },
      cancel: function cancel(model) {
        model.rollbackAttributes();
        return this.transitionToRoute('admin.strength-assessment.index');
      }
    }
  });
});