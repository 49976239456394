define('app-ember/services/model-entities/users', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service('store'),

    fetchUser: function fetchUser(id) {
      return this.get('store').find('token-authenticate-me/user', id);
    },
    fetchUserWithUuid: function fetchUserWithUuid() {
      var queryParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      return this.get('store').queryRecord('token-authenticate-me/user', Object.assign({}, queryParams));
    },
    fetchUsers: function fetchUsers() {
      var queryParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var defaultQueryParams = {};

      return this.get('store').query('token-authenticate-me/user', Object.assign({}, defaultQueryParams, queryParams));
    }
  });
});