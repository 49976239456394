define('app-ember/components/login-form', ['exports', 'app-ember/templates/components/login-form'], function (exports, _loginForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _loginForm.default,
    tagName: '',

    _isProcessing: false,

    actions: {
      login: function login(username, password) {
        var _this = this;

        this.set('_isProcessing', true);
        return this.get('login')(username, password).finally(function () {
          _this.set('_isProcessing', false);
        });
      }
    }
  });
});