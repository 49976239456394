define('app-ember/controllers/story-creation/more-attributes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    storyCreation: Ember.inject.controller(),
    storyNumber: Ember.computed.alias('storyCreation.storyNumber'),
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser'),
    currentProfile: Ember.computed.alias('session.content.currentUser.profileable'),

    actions: {
      onBack: function onBack() {
        this.transitionToRoute('story-creation');
      },
      onMoreAttributesNext: function onMoreAttributesNext() {
        this.transitionToRoute('story-creation.story-cue1');
      },
      transitionToProfile: function transitionToProfile() {
        this.transitionToRoute('member.profile', this.get('currentProfile'));
      }
    }
  });
});