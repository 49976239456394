define('app-ember/helpers/minus-numbers', ['exports', 'ember-bootstrap-controls/helpers/minus-numbers'], function (exports, _minusNumbers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _minusNumbers.default;
    }
  });
  Object.defineProperty(exports, 'minusNumbers', {
    enumerable: true,
    get: function () {
      return _minusNumbers.minusNumbers;
    }
  });
});