define('app-ember/components/ws-eval-card', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    questionAnswerModels: [],
    questions: null,
    isSaving: null,
    isUpdatingStrengths: null,
    currentUser: null,
    primerCompleted: false,

    currentQuestionIndex: Ember.computed('questionAnswerModels', 'currentUser', function () {
      var strengthsAssessmentRequired = this.get('currentUser.strengthsAssessmentRequired');
      var questionAnswerModels = this.get('questionAnswerModels');
      var arr = void 0;

      if (questionAnswerModels) {
        arr = questionAnswerModels.map(function (answerModel) {

          if (strengthsAssessmentRequired) {
            if (answerModel.get('strengthRating')) {
              return answerModel;
            }
          } else {
            if (answerModel.get('answer') !== 'No answer') {
              return answerModel;
            }
          }
        }).compact();
      }

      return arr.length;
    }),

    currentQuestion: Ember.computed('currentQuestionIndex', 'questionAnswerModels', function () {
      var questionAnswerModels = this.get('questionAnswerModels');

      return questionAnswerModels.objectAt(this.get('currentQuestionIndex'));
    }),

    numQuestions: Ember.computed('questionAnswerModels', function () {
      var questionAnswerModels = this.get('questionAnswerModels');

      return questionAnswerModels.get('length');
    }),

    actions: {
      nextQuestion: function nextQuestion(currentQuestion) {
        var _this = this;

        return currentQuestion.save().then(function () {
          if (_this.get('currentQuestionIndex') + 1 !== _this.get('numQuestions')) {
            _this.incrementProperty('currentQuestionIndex');
          } else {
            _this.set('primerCompleted', true);
          }
        });
      },
      goBack: function goBack(currentQuestion) {
        return this.decrementProperty('currentQuestionIndex');
      }
    }
  });
});