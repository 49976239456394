define('app-ember/components/public-display/employment-history-panel', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    employmentDetails: null,
    isEmploymentPanelOpen: null
  });
});