define('app-ember/models/role', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.MEMBER = exports.RECRUITER = exports.ORG_ADMIN = exports.SITE_ADMIN = undefined;
  var SITE_ADMIN = exports.SITE_ADMIN = "WholeStory Site Admin";
  var ORG_ADMIN = exports.ORG_ADMIN = "Organization Admin";
  var RECRUITER = exports.RECRUITER = "Recruiter";
  var MEMBER = exports.MEMBER = "Member";

  exports.default = _emberData.default.Model.extend({
    profileMembers: _emberData.default.hasMany('profile-member', { async: true }),
    profileBusinesses: _emberData.default.hasMany('profile-business', { async: true }),
    profileAdmins: _emberData.default.hasMany('profile-admin', { async: true }),

    label: _emberData.default.attr('string'),

    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),

    isOrgAdmin: Ember.computed('label', function () {
      var roleName = this.get('label');

      if (roleName) {
        return roleName === ORG_ADMIN;
      }
    }),

    isRecruiter: Ember.computed('label', function () {
      var roleName = this.get('label');

      if (roleName) {
        return roleName === RECRUITER;
      }
    }),

    isMember: Ember.computed('label', function () {
      var roleName = this.get('label');

      if (roleName) {
        return roleName === MEMBER;
      }
    }),

    humanLabel: Ember.computed('label', function () {
      var roleName = this.get('label');

      if (roleName && roleName === MEMBER) {
        return "Candidate";
      } else {
        return roleName;
      }
    })

  });
});