define('app-ember/components/bootstrap/edit-form', ['exports', 'ember-bootstrap-controls/components/bootstrap/edit-form'], function (exports, _editForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _editForm.default;
    }
  });
});