define('app-ember/routes/deactivated-account', ['exports', 'app-ember/routes/base-authenticated'], function (exports, _baseAuthenticated) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseAuthenticated.default.extend({
    usersEntity: Ember.inject.service('model-entities/users'),

    model: function model(params) {
      return this.get('usersEntity').fetchUser(params.user_id);
    }
  });
});