define('app-ember/controllers/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser'),
    profileable: Ember.computed.alias('currentUser.profileable'),
    showOldStoryPopup: Ember.computed.alias('currentUser.showOldStoryPopup'),
    role: Ember.computed.alias('profileable.role'),
    isMember: Ember.computed.alias('role.isMember'),
    routing: Ember.inject.service('-routing'),
    hasNavAccess: false,

    strengthsAssessmentRequired: Ember.computed.alias('currentUser.strengthsAssessmentRequired'),

    versionNotice: Ember.computed('showOldStoryPopup', 'noNav', function () {
      return !this.get('noNav') && this.get('showOldStoryPopup');
    }),

    fullScreen: Ember.computed('routing.currentRouteName', 'noNav', function () {
      var currentRoute = this.get('routing.currentRouteName');
      if (currentRoute === 'login') {
        return false;
      } else {
        return currentRoute.match(/organization.show.*/) || currentRoute.match(/admin.*/) || this.get('noNav');
      }
    }),

    noNav: Ember.computed('routing.currentRouteName', function () {
      var currentRoute = this.get('routing.currentRouteName');
      return currentRoute === 'login' || currentRoute === 'loading' || currentRoute === 'welcome' || currentRoute.match(/public.profile.*/);
    }),

    observeNavAccess: Ember.observer('noNav', function () {
      var _this = this;

      var profileable = this.get('profileable');
      var role = this.get('role');

      Ember.RSVP.hash({ role: role, profileable: profileable }).then(function (hash) {
        if (role && profileable) {
          if (!role.get('isMember') || profileable.get('hasCompletedOnboarding')) {
            return _this.set('hasNavAccess', true);
          } else {
            return _this.set('hasNavAccess', false);
          }
        } else {
          _this.set('hasNavAccess', false);
        }
      });
    }),

    actions: {
      dismissStoryPrimerReminder: function dismissStoryPrimerReminder() {
        this.set('strengthsAssessmentRequired', false);
      }
    }
  });
});