define('app-ember/controllers/public/profile/video', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      onHidden: function onHidden() {
        return this.transitionToRoute('public.profile', this.get('model'));
      }
    }
  });
});