define('app-ember/models/three-week-survey', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    profileMember: _emberData.default.belongsTo('profile-member', { async: true }),

    numberOfStories: _emberData.default.attr('number'),

    interviewAnswer: _emberData.default.attr('string'),
    interviewDetails: _emberData.default.attr('string'),

    jobOfferAnswer: _emberData.default.attr('string'),
    jobOfferDetails: _emberData.default.attr('string'),

    jobAcceptanceAnswer: _emberData.default.attr('string'),
    jobAcceptanceDetails: _emberData.default.attr('string'),

    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date')
  });
});