define('app-ember/components/story-creation/story-type-selection-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    createAttributeStory: null,
    createTopicStory: null,
    isOpen: null,
    open: null

  });
});