define('app-ember/components/bootstrap-pagination-nav/page-link', ['exports', 'ember-bootstrap-controls/components/bootstrap-pagination-nav/page-link'], function (exports, _pageLink) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _pageLink.default;
    }
  });
});