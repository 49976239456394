define('app-ember/transitions/flip', ['exports', 'liquid-fire'], function (exports, _liquidFire) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = flip;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function flip(dimension, opts) {
    var _this = this;

    var property = void 0,
        firstStep = void 0;
    var flippingElement = findFlippingElement(this);

    if (!opts) {
      opts = {};
    }

    if (flippingElement) {
      // We still have some older version that is in the process of
      // revealing, so our first step is waiting for it to finish.
      firstStep = (0, _liquidFire.finish)(flippingElement, 'flip-out');
    } else {
      firstStep = _liquidFire.Promise.resolve();
    }

    return firstStep.then(function () {

      if (dimension.toLowerCase() === 'x') {
        property = 'rotateX';
      } else {
        property = 'rotateY';
      }

      var firstRotation = {};
      firstRotation[property] = '90deg';

      var secondRotation = {};
      secondRotation[property] = ['0deg', '-90deg'];

      var easeIn = 'ease-in',
          easeOut = 'ease-out';

      if (opts.easing) {
        var _opts$easing = _slicedToArray(opts.easing, 2);

        easeIn = _opts$easing[0];
        easeOut = _opts$easing[1];
      }
      var duration = 100;
      if (opts.duration) {
        duration = opts.duration / 2;
      }

      var firstOpts = new Map([opts, {
        easing: easeIn,
        duration: duration
      }]);
      var secondOpts = new Map([opts, {
        easing: easeOut,
        duration: duration
      }]);

      var container = _this.oldElement.parent('.liquid-container');
      container.css({
        'perspective': opts.perspective ? opts.perspective : '1000px',
        'overflow': 'visible'
      });

      _this.newElement.css('visibility', 'hidden');
      return (0, _liquidFire.animate)(_this.oldElement, firstRotation, firstOpts, 'flip-out').then(function () {
        _this.oldElement.css('visibility', 'hidden');
        _this.newElement.css('visibility', '');
        return (0, _liquidFire.animate)(_this.newElement, secondRotation, secondOpts, 'flip-out').then(function () {
          container.css({
            'perspective': '',
            'overflow': ''
          });
        });
      });
    });
  }

  function findFlippingElement(context) {
    for (var i = 0; i < context.older.length; i++) {
      var entry = context.older[i];
      if ((0, _liquidFire.isAnimating)(entry.element, 'flip-out')) {
        return entry.element;
      }
    }
    if ((0, _liquidFire.isAnimating)(context.oldElement, 'flip-out')) {
      return context.oldElement;
    }
  }
});