define('app-ember/models/interview-question', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    question: _emberData.default.attr('string'),
    context: _emberData.default.attr('string'),
    dynamicText: _emberData.default.attr('boolean'),
    storyStoryAttributes: _emberData.default.hasMany('story-attribute', { async: true }),
    storyTopics: _emberData.default.hasMany('topic', { async: true }),

    isStoryBasedQuestion: _emberData.default.attr('boolean'),
    isProfileBasedQuestion: _emberData.default.attr('boolean'),

    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date')
  });
});