define('app-ember/routes/organization/show/invitations', ['exports', 'app-ember/routes/base-authenticated'], function (exports, _baseAuthenticated) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseAuthenticated.default.extend({
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('organization', this.modelFor('organization/show'));
    }
  });
});