define('app-ember/routes/admin/members', ['exports', 'app-ember/routes/admin', 'ember-bootstrap-controls/mixins/routes/pagination'], function (exports, _admin, _pagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _admin.default.extend(_pagination.default, {
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser'),
    usersEntity: Ember.inject.service('model-entities/users'),
    organizationsEntity: Ember.inject.service('model-entities/organizations'),

    queryParams: {
      pageNumber: { refreshModel: true },
      pageSize: { refreshModel: true },
      sortCriteria: { refreshModel: true },
      sortReverse: { refreshModel: true },
      filterOrganization: { refreshModel: true },
      recruiterFilter: { refreshModel: true }
    },

    model: function model(params) {
      var filters = {
        profileable_type: 'ProfileMember'
      };

      if (params.filterOrganization) {
        if (params.filterOrganization === "Unaffiliated") {
          filters.non_organization_member = true;
        } else {
          filters.organization_member = params.filterOrganization;
        }
      }

      if (params.recruiterFilterId) {
        filters.invited_by_recruiter = params.recruiterFilterId;
      }

      return this.get('usersEntity').fetchUsers({
        page: this.paginationParams(params),
        sort: {
          criteria: params.sortCriteria,
          reverse: params.sortReverse
        },
        filters: filters
      });
    },
    afterModel: function afterModel(model, params) {
      var _this = this;

      this._super.apply(this, arguments);

      return this.get('organizationsEntity').fetchOrganizations().then(function (organizations) {
        return _this.set('organizations', organizations);
      }).then(function () {
        if (params.queryParams.recruiterFilterId) {
          return Ember.RSVP.hash({
            organization: _this.get('store').find('organization', params.queryParams.filterOrganization),
            recruiter: _this.get('store').find('token-authenticate-me/user', params.queryParams.recruiterFilterId)
          }).then(function (hash) {
            _this.set('filterOrganization', hash.organization);
            _this.set('filterOrganizationLabel', hash.organization.get('label'));
            _this.set('recruiterFilter', hash.recruiter);
            _this.set('recruiterFilterId', params.queryParams.recruiterFilterId);
            return hash;
          });
        } else if (params.queryParams.filterOrganization) {
          if (params.queryParams.filterOrganization === "Unaffiliated") {
            _this.set('filterOrganization', "Unaffiliated");
            _this.set('filterOrganizationLabel', "Unaffiliated");
          } else {
            return Ember.RSVP.resolve(_this.get('store').find('organization', params.queryParams.filterOrganization)).then(function (organization) {
              _this.set('filterOrganization', organization.get('id'));
              _this.set('filterOrganizationLabel', organization.get('label'));
              return organization;
            });
          }
        } else {
          _this.set('filterOrganization', null);
          _this.set('filterOrganizationLabel', null);
          _this.set('recruiterFilter', null);
          _this.set('recruiterFilterId', null);
        }
      });
    },


    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('organizations', this.get('organizations'));
      controller.set('filterOrganization', this.get('filterOrganization'));
      controller.set('filterOrganizationLabel', this.get('filterOrganizationLabel'));
      controller.set('recruiterFilter', this.get('recruiterFilter'));
      controller.set('recruiterFilterId', this.get('recruiterFilterId'));
    },

    resetController: function resetController(controller, isExiting /*, transition*/) {
      if (isExiting) {
        controller.set('filterOrganization', null);
        controller.set('filterOrganizationLabel', null);
        controller.set('recruiterFilter', null);
        controller.set('recruiterFilterId', null);
        controller.set('sortCriteria', "id");
        controller.set('sortReverse', false);
        controller.set('pageNumber', 1);
      }
    },


    actions: {
      loading: function loading() {
        return true;
      }
    }
  });
});