define('app-ember/routes/video-builder', ['exports', 'app-ember/routes/base-authenticated'], function (exports, _baseAuthenticated) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseAuthenticated.default.extend({
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser')
  });
});