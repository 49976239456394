define('app-ember/models/token-authenticate-me/user', ['exports', 'ember-data', 'app-ember/models/role'], function (exports, _emberData, _role) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _DS$Model$extend;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  exports.default = _emberData.default.Model.extend((_DS$Model$extend = {
    // role: DS.belongsTo('role', { async: true }),

    profileable: _emberData.default.belongsTo('models/profileable', { async: true, polymorphic: true, inverse: 'user' }),

    username: _emberData.default.attr('string'),
    needToChangeUsername: _emberData.default.attr('boolean'),
    strengthsAssessmentRequired: _emberData.default.attr('boolean'),
    email: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    zipCode: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    muxVideoId: _emberData.default.attr('string'),
    videoPrivacy: _emberData.default.attr('string', { defaultValue: 'nobody' }),

    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),

    profileable_id: _emberData.default.attr('number'),
    profileable_type: _emberData.default.attr('string'),

    isSiteAdmin: _emberData.default.attr('boolean'),
    showOldStoryPopup: _emberData.default.attr('boolean'),

    /* not persisted in the local store, only sent to server */
    password: null,
    passwordConfirmation: null,
    currentPassword: null,

    emailConfirmation: null,

    videoLink: Ember.computed('muxVideoId', function () {
      return 'https://stream.mux.com/' + this.get('muxVideoId') + '.m3u8';
    }),

    canManageInvitations: Ember.computed('profileable.role.label', function () {
      var roleName = this.get('profileable.role.label');

      if (roleName) {
        return roleName === _role.ORG_ADMIN || roleName === _role.RECRUITER;
      }
    })

  }, _defineProperty(_DS$Model$extend, 'isSiteAdmin', Ember.computed('profileable.role.label', function () {
    var roleName = this.get('profileable.role.label');

    if (roleName) {
      return roleName === _role.SITE_ADMIN;
    } else {
      return false;
    }
  })), _defineProperty(_DS$Model$extend, 'isOrgAdmin', Ember.computed('profileable.role.label', function () {
    var roleName = this.get('profileable.role.label');

    if (roleName) {
      return roleName === _role.ORG_ADMIN;
    } else {
      return false;
    }
  })), _defineProperty(_DS$Model$extend, 'isRecruiter', Ember.computed('profileable.role.label', function () {
    var roleName = this.get('profileable.role.label');

    if (roleName) {
      return roleName === _role.RECRUITER;
    } else {
      return false;
    }
  })), _defineProperty(_DS$Model$extend, 'isMember', Ember.computed('profileable.role.label', function () {
    var roleName = this.get('profileable.role.label');

    if (roleName) {
      return roleName === _role.MEMBER;
    }
  })), _defineProperty(_DS$Model$extend, 'fullName', Ember.computed('firstName', 'lastName', function () {
    return this.get('firstName') + ' ' + this.get('lastName');
  })), _defineProperty(_DS$Model$extend, 'hasNoUsername', Ember.computed('username', 'firstName', function () {
    var username = this.get('username');
    var firstName = this.get('firstName');

    if (username !== null && firstName !== null) {
      return false;
    } else {
      return true;
    }
  })), _defineProperty(_DS$Model$extend, 'storyLength', Ember.computed('profileable.stories.[]', function () {
    var stories = this.get('profileable.stories');

    if (stories) {
      return stories.get('length');
    }
  })), _defineProperty(_DS$Model$extend, 'onboardingCompleted', Ember.computed('profileable.hasCompletedOnboarding', function () {
    var completed = this.get('profileable.hasCompletedOnboarding');
    return completed;
  })), _defineProperty(_DS$Model$extend, 'adapterDidInvalidate', function adapterDidInvalidate(errors) {
    var recordErrors = Ember.get(this, 'errors');
    var extraProperties = ['password', 'passwordConfirmation', 'currentPassword'];

    function addError(name) {
      if (errors[name]) {
        recordErrors.add(name, errors[name]);
      }
    }

    extraProperties.forEach(addError);
    this.eachAttribute(addError);
    this.eachRelationship(addError);
    this._saveWasRejected();
  }), _defineProperty(_DS$Model$extend, 'passwordUpdate', function () {
    var errors = this.get('errors');

    if (errors) {
      errors.remove('password');
    }
  }.observes('password')), _defineProperty(_DS$Model$extend, 'passwordConfirmationUpdate', function () {
    var errors = this.get('errors');

    if (errors) {
      errors.remove('passwordConfirmation');
    }
  }.observes('passwordConfirmation')), _defineProperty(_DS$Model$extend, 'currentPasswordUpdate', function () {
    var errors = this.get('errors');

    if (errors) {
      errors.remove('currentPassword');
    }
  }.observes('currentPassword')), _DS$Model$extend));
});