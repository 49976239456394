define('app-ember/components/story-creation/sample-stories', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    router: Ember.inject.service('router'),
    currentExampleIndex: null,
    exampleStories: [],

    currentExampleStory: Ember.computed('currentExampleIndex', 'exampleStories', function () {
      return this.get('exampleStories')[this.get('currentExampleIndex')];
    }),
    maxIndex: Ember.computed('exampleStories.[]', function () {
      return this.get('exampleStories.length') - 1;
    }),

    transitionRoute: null,

    init: function init() {
      this._super.apply(this, arguments);

      this.set('exampleStories', [{
        topic: 'College Soccer',
        qualities: ['Humility', 'Teamwork'],
        statement: 'In a team setting, I am able to contribute and collaborate without the need to be the "star."',
        proof: 'I played college soccer and never became the star that I had envisioned. Despite sitting on the bench more than I played, I showed up everyday and worked hard to help our team get to Nationals. I learned that collective accomplishment is so much more satisfying than personal recognition.'
      }, {
        topic: 'Chronic illness',
        qualities: ['Openness to Feedback', 'Self-Awareness', 'Adaptability'],
        statement: 'I am able to listen deeply, ask questions, and remain open to all possibilities.',
        proof: 'In 2017, I was diagnosed with a brain tumor that brought the unknown struggles of the previous five years in to focus. My perspective of myself and the world expanded significantly as I faced uncertainty, significant discomfort, and fatigue every day. I used to be a more close-minded person—this experience cracked me open in the best possible way.'
      }, {
        topic: 'Intercultural Marriage',
        qualities: ['Empathy', 'Adaptability'],
        statement: 'I can confidently navigate complex, dynamic situations involving people from widely varying backgrounds.',
        proof: 'Nine years ago I married my wife Zahra, who is the daughter of Pakistani immigrants. This relationship has transformed in my understanding of humanity\'s multifaceted richness, first as a husband, and later as the father of our three children. Through the lens of our marriage, I\'ve come to witness and embrace the power of diversity in all of its forms.'
      }, {
        topic: 'Growing up amid gang violence',
        qualities: ['Courage', 'Adaptability', 'Self-Awareness'],
        statement: 'I am adept at making strangers feel at ease in my presence.',
        proof: 'As a teen, the bus I took to get to work and school crossed multiple gang lines. I was constantly afraid of being mistaken as a rival gang member and getting beaten up, or worse, being violently killed. Reading body language and presenting myself in a non-threatening way was a matter of survival. This has become a skill I use today to connect with people in my personal and professional life.'
      }, {
        topic: 'Dyslexia',
        qualities: ['Perseverance', 'Courage', 'Creativity'],
        statement: 'I have the capacity to think big picture, synthesize information, and communicate clearly.',
        proof: 'Having Dyslexia forced me to be creative in how I learn and communicate. In my early 30s, I discovered that my struggle and dislike of reading and writing—lasting well in to my late 20s— were likely due to Dyslexia. With maturity, discipline, and a deepening curiosity, I’ve developed a voracious appetite for reading and a knack for writing.'
      }, {
        topic: 'Business Failure',
        qualities: ['Humility', 'Self-awareness', 'Openness to feedback'],
        statement: 'I listen to critical input from others and use it as a catalyst for growth.',
        proof: 'When my multimillion dollar business failed in 2010, I had to take a long hard look at where I\u2019d gone wrong. While searching for answers, I had to put my ego aside, open myself up to the feedback I wasn\'t willing to hear before, and take responsibility for my own role in the situation. As the CEO, I\u2019d surrounded myself by \u201Cyes men\u201D because I didn\u2019t want to hear bad news. Now, I lean into feedback at the first sign of trouble and I try to hear the critiques I avoided listening to in the past.'
      }, {
        topic: 'Death of my brother',
        qualities: ['Purpose', 'Courage', 'Teamwork'],
        statement: ' I operate from a thoroughly-tested set of core values even when the going gets tough.',
        proof: 'After my closest brother Luke died, I spent seven years grieving and learning from his death, which eventually led me to a profound awareness of the interconnectedness of all things. This process freed me from most forms of fear, which has translated to a willingness to innovate, and it catalyzed an opening of the heart that helped me relate to people less selfishly.'
      }, {
        topic: 'Backcountry Guiding',
        qualities: ['Steadiness', 'Responsibility', 'Courage'],
        statement: 'In high stakes scenarios, I am able to remain calm under pressure and communicate clearly.',
        proof: 'I spent two years backcountry guiding in the remote wilderness of Montana, far from radio/cell contact and in many high-risk situations. I developed a strong work ethic and a capacity for quick, level-headed decision making. These experiences set a baseline for me against which most future stressful situations have paled.'
      }, {
        topic: 'Recovery',
        qualities: ['Self Awareness', 'Humility', 'Integrity'],
        statement: 'I have a deep understanding of my strengths and weaknesses as a leader.',
        proof: 'I quit drinking more than 11 years ago after finally realizing that my relationship to alcohol was not serving my life goals. In the years that followed, I re-established an even deeper connection to the values I was raised with. My strong work ethic, collaborative spirit, and lifelong commitment to self-reflection are all products of my recovery journey.'
      }, {
        topic: 'U.S. Navy Boot-Camp',
        qualities: ['Teamwork', 'Grit'],
        statement: 'I am a team player in high-pressure situations.',
        proof: 'In Navy boot camp, I trained with 200 men from a diverse range of backgrounds. This experience forced me to see past our individual differences and focus my energy on our shared mission. Only by relying on each other could the platoon succeed as a whole. I pushed through my physical and psychological limits and put the needs of the group ahead of my own. Now, when I face challenges and setbacks, I work collaboratively with others to identify the needs and map a solution.'
      }]);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.exampleSet && this.exampleSet === 2) {
        var examples = this.exampleStories;
        examples.splice(0, 4);
        this.set('exampleSet', examples);
      } else {
        var _examples = this.exampleStories;
        _examples.splice(4, 6);
        this.set('exampleSet', _examples);
      }
      if (this.get('currentExampleIndex') > this.get('maxIndex')) {
        this.set('currentExampleIndex', this.get('currentExampleIndex') - this.get('maxIndex'));
      }
    },


    actions: {
      nextExample: function nextExample(index, modifier) {
        this.set('currentExampleIndex', index + modifier);
      }
    }
  });
});