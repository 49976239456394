define('app-ember/controllers/admin/organizations', ['exports', 'ember-light-table'], function (exports, _emberLightTable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['pageNumber', 'pageSize', 'sortCriteria', 'sortReverse'],
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser'),
    media: Ember.inject.service(),

    selectedOrganizations: [],
    pageNumber: 1,
    pageSize: 15,
    totalRecords: null,
    currentIterationCount: null,

    sortCriteria: "id",
    sortReverse: false,

    cellWidth: Ember.computed('media', function () {
      var media = this.get('media');

      if (media.get('isMobile')) {
        return '9rem';
      } else {
        return 'null';
      }
    }),

    actions: {
      selectOrg: function selectOrg(org) {
        if (this.get('selectedOrganizations').includes(org)) {
          return this.get('selectedOrganizations').removeObject(org);
        } else {
          return this.get('selectedOrganizations').pushObject(org);
        }
      },
      onColumnSort: function onColumnSort(column) {
        this.set('sortCriteria', column.get('valuePath'));
        this.set('sortReverse', column.ascending ? false : true);
      }
    },

    columns: Ember.computed(function () {
      return [{
        label: 'Organization',
        valuePath: 'label',
        cellComponent: 'table-cell/organization-link',
        width: this.get('cellWidth')
      }, {
        label: 'Primary Contact',
        valuePath: 'primaryContactProfile.user.fullName',
        sortable: false,
        width: this.get('cellWidth')
      }, {
        label: 'Phone Number',
        valuePath: 'phone',
        width: this.get('cellWidth')
      }, {
        label: 'Email',
        valuePath: 'primaryContactProfile.user.email',
        sortable: false,
        width: this.get('cellWidth')
      }, {
        label: 'Status',
        valuePath: 'status',
        width: this.get('cellWidth')
      }, {
        label: 'Video',
        valuePath: 'videoFeatureEnabled',
        sortable: false,
        cellComponent: 'table-cell/video-feature',
        width: this.get('cellWidth')
      }];
    }),

    table: Ember.computed('model', function () {

      var model = this.get('model').toArray();

      var table = new _emberLightTable.default(this.get('columns'), model, { enableSync: true });

      return table;
    })

  });
});