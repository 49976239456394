define('app-ember/routes/admin/organizations', ['exports', 'app-ember/routes/admin', 'ember-bootstrap-controls/mixins/routes/pagination'], function (exports, _admin, _pagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _admin.default.extend(_pagination.default, {
    session: Ember.inject.service('session'),
    organizationsEntity: Ember.inject.service('model-entities/organizations'),
    queryParams: {
      pageNumber: { refreshModel: true },
      pageSize: { refreshModel: true },
      sortCriteria: { refreshModel: true },
      sortReverse: { refreshModel: true }
    },

    model: function model(params) {
      return this.get('organizationsEntity').fetchOrganizations({
        page: this.paginationParams(params),
        sort: {
          criteria: params.sortCriteria,
          reverse: params.sortReverse
        }
      });
    },


    afterModel: function afterModel(model) {
      this._super.apply(this, arguments); // Call the afterModel from the mixin
    },

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('currentUser', this.get('session.content.currentUser'));
    },

    resetController: function resetController(controller, isExiting /*, transition*/) {
      if (isExiting) {
        controller.set('sortCriteria', "id");
        controller.set('sortReverse', false);
        controller.set('pageNumber', 1);
      }
    },


    actions: {
      loading: function loading() {
        return true;
      }
    }
  });
});