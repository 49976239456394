define('app-ember/routes/admin/recruiters', ['exports', 'app-ember/routes/admin', 'ember-bootstrap-controls/mixins/routes/pagination'], function (exports, _admin, _pagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _admin.default.extend(_pagination.default, {
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser'),
    usersEntity: Ember.inject.service('model-entities/users'),
    organizationsEntity: Ember.inject.service('model-entities/organizations'),

    queryParams: {
      pageNumber: { refreshModel: true },
      pageSize: { refreshModel: true },
      sortCriteria: { refreshModel: true },
      sortReverse: { refreshModel: true },
      organizationFilterId: { refreshModel: true }
    },

    model: function model(params) {
      var filters = {
        profileable_type: 'ProfileBusiness',
        only_recruiters: true
      };

      if (params.organizationFilterId) {
        filters.organization_user = params.organizationFilterId;
      }

      return this.get('usersEntity').fetchUsers({
        page: this.paginationParams(params),
        sort: {
          criteria: params.sortCriteria,
          reverse: params.sortReverse
        },
        filters: filters
      });
    },
    afterModel: function afterModel(model, params) {
      var _this = this;

      this._super.apply(this, arguments);

      return this.get('organizationsEntity').fetchOrganizations().then(function (organizations) {
        return _this.set('organizations', organizations);
      }).then(function () {
        if (params.queryParams.organizationFilterId) {
          return Ember.RSVP.resolve(_this.get('store').find('organization', params.queryParams.organizationFilterId)).then(function (organization) {
            _this.set('organizationFilterId', organization.get('id'));
            _this.set('organizationFilterLabel', organization);
            return organization;
          });
        } else {
          _this.set('organizationFilterId', null);
          _this.set('organizationFilterLabel', null);
        }
      });
    },


    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('organizations', this.get('organizations'));
      controller.set('organizationFilterId', this.get('organizationFilterId'));
      controller.set('organizationFilterLabel', this.get('organizationFilterLabel'));
    },

    resetController: function resetController(controller, isExiting /*, transition*/) {
      if (isExiting) {
        controller.set('organizations', null);
        controller.set('organizationFilterId', null);
        controller.set('organizationFilterLabel', null);
        controller.set('sortCriteria', "id");
        controller.set('sortReverse', false);
        controller.set('pageNumber', 1);
      }
    },


    actions: {
      loading: function loading() {
        return true;
      }
    }
  });
});