define('app-ember/components/freestyle-showdown-content', ['exports', 'ember-freestyle/components/freestyle-showdown-content'], function (exports, _freestyleShowdownContent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _freestyleShowdownContent.default;
    }
  });
});