define('app-ember/components/ws-progress-card', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['col-12', 'col-md-10', 'col-lg-8', 'mx-auto', 'd-flex', 'flex-row'],
    storyPrimerCompleted: Ember.computed('evalStepsCompleted', 'evalSteps', function () {
      return this.get('evalStepsCompleted') >= this.get('evalSteps');
    }),
    storyBuilderClassNames: Ember.computed('evalStepsCompleted', 'evalSteps', function () {
      return 'col-4 pl-0 ' + (this.get('storyPrimerCompleted') ? '' : 'inactive-progress');
    })
  });
});