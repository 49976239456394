define('app-ember/models/profile-admin', ['exports', 'ember-data', 'app-ember/mixins/models/profileable'], function (exports, _emberData, _profileable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_profileable.default, {
    role: _emberData.default.belongsTo('role', { async: true }),

    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date')
  });
});