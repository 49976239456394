define('app-ember/services/model-entities/education-details', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service('store'),

    fetchEducationDetail: function fetchEducationDetail(id) {
      return this.get('store').find('education-detail', id);
    },
    fetchEducationDetails: function fetchEducationDetails() {
      var queryParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var defaultQueryParams = {};

      return this.get('store').query('education-detail', Object.assign({}, defaultQueryParams, queryParams));
    }
  });
});