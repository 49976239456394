define('app-ember/components/table-cell/video-feature', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    value: null,
    row: null,
    column: null,

    notify: Ember.inject.service('notify'),

    actions: {
      toggleVideoFeature: function toggleVideoFeature(row) {
        var _this = this;

        var organization = row.get('content');
        organization.set('videoFeatureEnabled', !organization.get('videoFeatureEnabled'));
        return organization.save().then(function (savedOrganization) {
          if (organization.get('videoFeatureEnabled')) {
            return _this.get('notify').success('Video feature enabled for ' + savedOrganization.get('label'));
          } else {
            return _this.get('notify').success('Video feature disabled for ' + savedOrganization.get('label'));
          }
        });
      }
    }
  });
});