define('app-ember/routes/organization/show', ['exports', 'app-ember/routes/base-authenticated'], function (exports, _baseAuthenticated) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseAuthenticated.default.extend({
    model: function model(params) {
      return this.get('store').find('organization', params.organization_id);
    }
  });
});