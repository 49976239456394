define('app-ember/components/freestyle/bootstrap/tables/action-bar/-action', ['exports', 'ember-bootstrap-controls/components/freestyle/bootstrap/tables/action-bar/-action'], function (exports, _action) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _action.default;
    }
  });
});