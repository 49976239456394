define('app-ember/controllers/public/profile', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser'),
    suggestedInterviewQuestions: null,
    v2Stories: null,

    selectedFilterTopics: null,
    selectedFilterQualities: null,

    storyQualities: null,
    storyTopics: null,

    showVideo: Ember.computed('model.{muxVideoId,displayVideo}', 'currentUser.profileable.organization.id', 'userProfile.organization.id', function () {
      if (this.get('model.muxVideoId')) {
        var viewerOrgId = this.get('currentUser.profileable.organization.id');
        var profileOrgId = this.get('userProfile.organization.id');
        var organizationMatches = viewerOrgId === profileOrgId;
        var publicVisable = this.get('model.videoPrivacy') === "anyone";
        var orgVisable = this.get('model.videoPrivacy') === "organization" && organizationMatches;
        return publicVisable || orgVisable;
      } else {
        return false;
      }
    }),

    filteredV2Stories: Ember.computed('selectedFilterTopics.[]', 'selectedFilterQualities.[]', 'v2Stories', function () {
      var selectedFilterTopics = this.get('selectedFilterTopics');
      var selectedFilterQualities = this.get('selectedFilterQualities');
      var v2Stories = this.get('v2Stories');

      if (selectedFilterTopics || selectedFilterQualities) {
        var filteredStories = [];

        if (selectedFilterTopics && selectedFilterQualities) {
          v2Stories.forEach(function (story) {
            if (selectedFilterTopics.includes(story.get('topic.label'))) {
              filteredStories.pushObject(story);
            }

            story.get('storyAttributes').forEach(function (quality) {
              if (selectedFilterQualities.includes(quality.get('label'))) {
                filteredStories.pushObject(story);
              }
            });
          });
        } else if (selectedFilterTopics && !selectedFilterQualities) {
          v2Stories.forEach(function (story) {
            if (selectedFilterTopics.includes(story.get('topic.label'))) {
              filteredStories.pushObject(story);
            }
          });
        } else if (selectedFilterQualities && !selectedFilterTopics) {
          v2Stories.forEach(function (story) {
            story.get('storyAttributes').forEach(function (quality) {
              if (selectedFilterQualities.includes(quality.get('label'))) {
                filteredStories.pushObject(story);
              }
            });
          });
        }

        filteredStories.sort(function (a, b) {
          if (!a.get('favorite') && b.get('favorite')) {
            return 1;
          }
          if (a.get('favorite') && !b.get('favorite')) {
            return -1;
          }
          return 0;
        });

        return filteredStories.uniq();
      } else {
        var v2StoriesArray = v2Stories.toArray();

        v2StoriesArray.sort(function (a, b) {
          if (!a.get('favorite') && b.get('favorite')) {
            return 1;
          }
          if (a.get('favorite') && !b.get('favorite')) {
            return -1;
          }
          return 0;
        });

        return v2StoriesArray;
      }
    }),

    publicProfileUrl: Ember.computed(function () {
      var user = this.get('model');

      if (user.get('profileable.profileUuid')) {
        return window.location.href;
      } else {
        return "N/A";
      }
    }),

    actions: {
      copySuccess: function copySuccess() {
        this.get('notify').success('Public URL copied to clipboard.');
      },
      copyError: function copyError() {
        this.get('notify').alert('Error with copying your public URL.');
      },
      onTopicClick: function onTopicClick(topic) {
        var selected = [];

        selected.pushObject(topic.get('label'));

        return this.set('selectedFilterTopics', selected);
      },
      onQualityClick: function onQualityClick(quality) {
        var selected = [];

        selected.pushObject(quality.get('label'));

        return this.set('selectedFilterQualities', selected);
      }
    }
  });
});