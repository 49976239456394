define('app-ember/controllers/self-eval/results', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser'),

    actions: {
      onNext: function onNext() {
        this.transitionToRoute('story-creation.sample-story');
      }
    }

  });
});