define('app-ember/helpers/is-active', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  exports.default = Ember.Helper.extend({
    router: Ember.inject.service('router'),
    currentPath: Ember.computed.alias('router._router.currentPath'),

    compute: function compute(_ref, hash) {
      var _ref2 = _toArray(_ref),
          routeName = _ref2[0],
          models = _ref2.slice(1);

      var allModels = void 0;
      if (hash.models) {
        allModels = models.concat(hash.models);
      } else {
        allModels = models;
      }

      return this.get('router').isActive(routeName);
    },


    didTransition: Ember.observer('currentPath', function () {
      this.recompute();
    })
  });
});