define("app-ember/components/candidate/employment-information-form", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    employmentModel: null,
    user: null,
    isNew: null,

    previousEndYear: null,
    previousEndMonth: null,

    monthSelection: ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"],
    yearSelection: [],

    init: function init() {
      this._super.apply(this, arguments);
      var back = 65;
      var year = new Date().getFullYear();
      this.set('yearSelection', Array.from({ length: back }, function (v, i) {
        return year - back + i + 1;
      }));
    },


    actions: {
      toggleCurrentEmployment: function toggleCurrentEmployment(employmentModel) {
        if (!employmentModel.get('currentlyEmployed')) {
          this.set('previousEndMonth', employmentModel.get('endMonth'));
          this.set('previousEndYear', employmentModel.get('endYear'));

          employmentModel.set('endMonth', null);
          employmentModel.set('endYear', null);
        } else {
          employmentModel.set('endMonth', this.get('previousEndMonth'));
          employmentModel.set('endYear', this.get('previousEndYear'));

          this.set('previousEndMonth', null);
          this.set('previousEndYear', null);
        }

        employmentModel.set('currentlyEmployed', !employmentModel.set('currentlyEmployed'));
      }
    }
  });
});