define('app-ember/serializers/application', ['exports', 'active-model-adapter', 'ember-inflector'], function (exports, _activeModelAdapter, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getHasManyPayloadKey = getHasManyPayloadKey;
  function getHasManyPayloadKey(key) {
    var singularKey = (0, _emberInflector.singularize)(key);
    var underscoredKey = Ember.String.underscore(singularKey);
    return underscoredKey + '_ids';
  }

  exports.default = _activeModelAdapter.ActiveModelSerializer.extend({
    attrs: {
      createdAt: { serialize: false },
      updatedAt: { serialize: false }
    },

    _getHasManyPayloadKey: function _getHasManyPayloadKey(key) {
      return getHasManyPayloadKey(key);
    },
    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      var key = relationship.key;

      json[this._getHasManyPayloadKey(key)] = snapshot.hasMany(key, { ids: true });
    }
  });
});