define('app-ember/components/freestyle/bootstrap/tables/-thead', ['exports', 'ember-bootstrap-controls/components/freestyle/bootstrap/tables/-thead'], function (exports, _thead) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _thead.default;
    }
  });
});