define('app-ember/components/public-display/stories/cloud-display-filter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    title: null,

    cloudDisplayOptions: null,
    selectedOptions: null,

    expandedStoryOptions: null,

    onOptionClick: null
  });
});