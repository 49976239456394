define('app-ember/components/freestyle/ws-strength-picker', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    value: 1,
    actions: {
      fakeAction: function fakeAction() {}
    }
  });
});