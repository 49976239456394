define('app-ember/components/sortable-table-header', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    columnLabel: null,
    sortCriteria: null,
    sortReverse: null,
    sortAttribute: null,

    actions: {
      toggleColumSorting: function toggleColumSorting(sortUp) {
        this.set('sortCriteria', this.get('sortAttribute'));
        this.set('sortReverse', sortUp);
      }
    }
  });
});