define('app-ember/components/generic/warning-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    isOpen: null,
    bodyContent: null,
    mustSelectAction: null,
    confirmBtnLabel: null,

    /* ACTIONS */
    acceptAction: null,
    cancelAction: null
  });
});