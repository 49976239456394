define('app-ember/components/ws-card/-cancel-button', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',
        buttonClasses: 'btn-default m-1'
    });
});