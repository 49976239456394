define('app-ember/controllers/quick-note/tip1', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    transitioningRoute: null,

    actions: {
      onQuickNoteNext: function onQuickNoteNext() {
        if (this.get('transitioningRoute') === "to-topic") {
          this.transitionToRoute('self-guided-story-builder.index');
        } else {
          this.transitionToRoute('story-creation');
        }
      }
    }
  });
});