define('app-ember/controllers/story-creation/achievement', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    storyCreation: Ember.inject.controller(),
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser'),
    storyNumber: Ember.computed.alias('storyCreation.storyNumber'),

    addingNewStory: false,
    isEditing: false,

    actions: {
      addNewStory: function addNewStory() {
        this.set('addingNewStory', true);
      },
      cancelAddStory: function cancelAddStory() {
        this.set('addingNewStory', false);
      },
      createTopicStory: function createTopicStory() {
        this.get('storyCreation').send('navigateToQuickNote', "to-topic");
      },
      createAttributeStory: function createAttributeStory() {
        this.send('reloadRoute');
        this.get('storyCreation').send('navigateToQuickNote', "to-attribute");
      },
      editStory: function editStory() {
        this.set('isEditing', true);
      }
    }
  });
});