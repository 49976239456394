define('app-ember/routes/member-survey/response-no', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.modelFor('member-survey');
    },
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);

      model.set('interviewAnswer', "no_interview");

      return model.save();
    }
  });
});