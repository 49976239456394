define('app-ember/routes/organization/show/search', ['exports', 'app-ember/routes/base-authenticated', 'ember-bootstrap-controls/mixins/routes/pagination'], function (exports, _baseAuthenticated, _pagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseAuthenticated.default.extend(_pagination.default, {
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.content.currentUser'),

    queryParams: {
      searchText: { refreshModel: true },
      searchByName: { refreshModel: true },
      searchByUsername: { refreshModel: true },
      searchByEmail: { refreshModel: true },
      attributeFilter: { refreshModel: true },
      topicFilter: { refreshModel: true },
      workSkillFilter: { refreshModel: true },
      pageNumber: { refreshModel: true },
      pageSize: { refreshModel: true },
      sortCriteria: { refreshModel: true },
      sortReverse: { refreshModel: true }
    },

    model: function model(params) {
      this.set('loading', true);
      var filters = {};

      if (params.searchByName & params.searchText != "") {
        filters.search_by_name = params.searchText;
      } else {
        delete filters.search_by_name;
      }

      if (params.searchByUsername & params.searchText != "") {
        filters.search_by_username = params.searchText;
      } else {
        delete filters.search_by_username;
      }

      if (params.searchByEmail & params.searchText != "") {
        filters.search_by_email = params.searchText;
      } else {
        delete filters.search_by_email;
      }

      if (params.attributeFilter) {
        filters.attribute_ids = params.attributeFilter;
      }

      if (params.topicFilter) {
        filters.topic_ids = params.topicFilter;
      }

      if (params.workSkillFilter) {
        filters.work_skill_ids = params.workSkillFilter;
      }

      return Ember.RSVP.hash({
        members: this.store.query('profile-member', {
          page: this.paginationParams(params),
          sort: {
            criteria: params.sortCriteria,
            reverse: params.sortReverse
          },
          filters: filters
        }),
        storyAttributes: this.store.findAll('storyAttribute'),
        topics: this.store.query('topic', {
          filters: {
            'published_v2_only': true
          }
        }),
        workSkills: this.store.findAll('workSkill')
      });
    },


    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('organization', this.modelFor('organization/show'));
    },

    afterModel: function afterModel(model, params) {
      this.set('loading', false);
      this.set('pageNumber', model.members.meta.page.offset);
      this.set('pageSize', model.members.meta.page.size);
      this.set('totalRecords', model.members.meta.page.total_records);
      this.set('totalPages', model.members.meta.page.total_pages);
      this.set('currentIterationCount', model.members.meta.page.current_iteration_count);
    }
  });
});