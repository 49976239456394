define('app-ember/routes/admin/strength-assessment/edit', ['exports', 'app-ember/routes/admin/strength-assessment'], function (exports, _strengthAssessment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _strengthAssessment.default.extend({
    store: Ember.inject.service('store'),
    model: function model(params) {
      return this.get('store').findRecord('survey-question', params.survey_question_id);
    }
  });
});